import React, { useCallback } from 'react'
import { Modal } from 'antd'
import { useListProjectsQuery, Project } from '@lumn-color/hooks'
import { useCreateProject } from 'api'
import { ProjectCard } from './ProjectCard'
import { CardList } from 'components/CardList'
import { oc } from 'ts-optchain'

const { confirm } = Modal

export function ProjectList() {
  const { loading, data } = useListProjectsQuery()
  const createProject = useCreateProject()
  const projects = oc(data).projects([])

  const onCreateProject = useCallback(() => {
    return confirm({
      title: 'Do you want to create a new project?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        createProject({
          name: 'Project',
          resolutionW: 500,
          resolutionH: 500,
        })
      },
    })
  }, [createProject])

  return (
    <CardList
      onCreate={onCreateProject}
      loading={loading}
      dataSource={projects}
      renderItem={renderItem}
    />
  )

  function renderItem(project: Project) {
    return <ProjectCard project={project} />
  }
}
