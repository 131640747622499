// TODO Support subscriptions ???

import { CacheResolver, InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { OperatorType } from 'constants/operators'
// import { onError } from 'apollo-link-error'
// import { ApolloLink } from 'apollo-link'
// import { WebSocketLink } from 'apollo-link-ws'
import { createHttpLink } from 'apollo-link-http'
// import { SubscriptionClient } from 'subscriptions-transport-ws'
import './deepDeletePolyfill'
import { serverHostname } from 'constants/environment'

const prodUrl = `https://${serverHostname}`
const devUrl = `http://${window.location.hostname}:4000`

const gqlUri = process.env.NODE_ENV === 'development' ? devUrl : prodUrl

// const gqlClient = new SubscriptionClient(gqlUri, {
// reconnect: true,
// })

export const client = new ApolloClient({
  link: createHttpLink({ uri: gqlUri }),
  // link: ApolloLink.from([
  // onError(({ graphQLErrors, networkError }) => {
  // if (graphQLErrors)
  // graphQLErrors.map(({ message, locations, path }) =>
  // console.log(
  // `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
  // ),
  // )
  // if (networkError) console.log(`[Network error]: ${networkError}`)
  // }),
  // new WebSocketLink(gqlClient),
  // ]),
  cache: new InMemoryCache({
    addTypename: true,
    cacheRedirects: {
      Query: {
        project: singleResolver('Project'),
        layer: singleResolver('Layer'),
        viewer: singleResolver('Viewer'),
        ...Object.values(OperatorType).reduce((acc, operatorType) => {
          const operatorTypeKey = keyify(operatorType)
          return {
            ...acc,
            [operatorTypeKey]: singleResolver(operatorType),
          }
        }, {}),
      },
    },
  }),
})

function keyify(type: string) {
  const first = type[0].toLowerCase()
  return `${first}${type.slice(1)}`
}

function singleResolver(__typename: string): CacheResolver {
  return (_: any, { where: { id } }: any, { getCacheKey }: any) =>
    getCacheKey({ __typename, id })
}
