import * as React from 'react'
import {
  Layout,
  // Typography
} from 'antd'
import { AppBreadcrumbs } from './AppBreadcrumbs'
import { AppContent } from './AppContent'
import { Nav } from 'components/Nav'
// import { buildVersion } from 'constant'

const {
  Content,
  // Footer
} = Layout
// const { Text } = Typography

export function AppLayout() {
  return (
    <Layout
      style={{
        height: '100vh',
        width: '100vw',
        overflowY: 'hidden',
        position: 'fixed',
      }}
    >
      <Nav />
      <Content
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '0 24px',
          paddingBottom: '16px',
          flexGrow: 1,
        }}
      >
        <AppBreadcrumbs />
        <div style={{ overflowY: 'scroll', WebkitOverflowScrolling: 'touch' }}>
          <AppContent />
        </div>
        <div style={{ flexGrow: 1 }} />
      </Content>
    </Layout>
  )
}

// <Footer
// style={{
// padding: '0 0 16px 16px',
// }}
// >
// <Text>version {buildVersion}</Text>
// </Footer>
