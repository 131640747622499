import { Label } from 'components/Field/Label'
import React, { useCallback, useRef, useState, useEffect } from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import tinycolor from 'tinycolor2'
import { GradientBuilder } from './GradientBuilder'
import { useUpdateRampKeys } from './useUpdateRampKeys'

export function GradientField({ rampId }: { rampId: string }) {
  const { keys, setKeys, syncing } = useUpdateRampKeys({
    rampId,
  })
  const [width, setWidth] = useState(100)

  const ref = useRef<HTMLDivElement | null>()

  const palette = keys.map(key => {
    const color = {
      r: key.colorR,
      g: key.colorG,
      b: key.colorB,
      a: key.colorA,
    }
    return {
      id: key.id,
      color: tinycolor(color).toRgbString(),
      pos: key.position,
    }
  })

  const setPalette = useCallback(
    (palette: any) => {
      setKeys(
        palette.map((stop: any) => {
          const { r, g, b, a } = tinycolor(stop.color).toRgb()
          return {
            id: stop.id,
            colorR: r,
            colorG: g,
            colorB: b,
            colorA: a,
            position: stop.pos,
          }
        }),
      )
    },
    [setKeys],
  )

  const size = useWindowSize()

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.getBoundingClientRect().width)
    }
  }, [size])

  return (
    <div
      style={{
        marginBottom: 10,
      }}
      ref={c => (ref.current = c)}
    >
      <Label syncing={syncing} title="Ramp Keys" />
      <div style={{ height: 4 }} />
      <GradientBuilder
        width={width}
        height={20}
        palette={palette}
        onPaletteChange={setPalette}
      />
    </div>
  )
}
