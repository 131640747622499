import React, { useCallback } from 'react'
import ColorStop from './ColorStop'

type Props = {
  width: number
  stops: {
    id: string
    color: string
    pos: number
    isActive: boolean
    pointX?: number
  }[]
  limits: { min: number; max: number; drop: number }
  onAddColor(arg: any): void
  onPosChange(arg: any): void
  onActivate(id: string): void
  onDeleteColor(id: string): void
}

export default function ColorStopsHolder(props: Props) {
  const handleMouseDown = useCallback(
    (event: any) => {
      event.preventDefault()
      if (event.button) return
      const pointX = event.clientX || event.touches[0].clientX
      const pos = pointX - event.target.getBoundingClientRect().left
      props.onAddColor({ pos, pointX })
      return false
    },
    [props],
  )

  const { width, stops, onAddColor, ...rest } = props

  const style = {
    width,
    height: 17,
    position: 'relative',
    cursor: 'crosshair',
  } as const

  return (
    <div
      className="csh"
      style={style}
      onTouchStart={handleMouseDown}
      onMouseDown={handleMouseDown}
    >
      {stops.map(stop => (
        <ColorStop key={stop.id} stop={stop} {...rest} />
      ))}
    </div>
  )
}
