import {
  ConstantFragment,
  CompositeFragment,
  TransformFragment,
  NoiseFragment,
  DisplaceFragment,
  RampFragment,
  RemapFragment,
} from '@lumn-color/hooks'

export const operatorKeys = [
  'constants',
  'noises',
  'ramps',
  'displaces',
  'remaps',
  'transforms',
  'composites',
] as const

export enum OperatorType {
  Constant = 'Constant',
  Ramp = 'Ramp',
  Noise = 'Noise',
  Displace = 'Displace',
  Remap = 'Remap',
  Transform = 'Transform',
  Composite = 'Composite',
}

export const operatoryQueryPath = {
  Constant: 'constant',
  Ramp: 'ramp',
  Noise: 'noise',
  Displace: 'displace',
  Remap: 'remap',
  Transform: 'transform',
  Composite: 'composite',
}

export type Operator =
  | ConstantFragment
  | CompositeFragment
  | TransformFragment
  | NoiseFragment
  | DisplaceFragment
  | RampFragment
  | RemapFragment
