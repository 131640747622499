import { useGetFullProjectQuery } from '@lumn-color/hooks'
import { RouteComponentProps } from 'react-router'
import useRouter from 'use-react-router'
import { oc } from 'ts-optchain'

export type ProjectRouteProps = {
  projectId?: string
}

export type ProjectRouteComponentProps = RouteComponentProps<ProjectRouteProps>

export function useRouterProject() {
  const routerProps = useRouter<ProjectRouteProps>()
  return useRouterProjectWithRouterProps(routerProps)
}

export function useRouterProjectWithRouterProps(
  routeProps: ProjectRouteComponentProps,
) {
  const projectId = routerProjectId(routeProps)
  const { data, loading } = useGetFullProjectQuery({
    skip: !projectId,
    variables: {
      where: { id: projectId },
    },
  })
  const project = data && data.project
  return { project, loading }
}

export function useIsRouterProject(projectId: string) {
  const routerProps = useRouter<ProjectRouteProps>()
  return projectId === routerProjectId(routerProps)
}

export function useRouterProjectId() {
  const routerProps = useRouter<ProjectRouteProps>()
  return routerProjectId(routerProps)
}

function routerProjectId({ location, match }: ProjectRouteComponentProps) {
  if (!location.pathname.startsWith('/projects/')) return null
  return oc(match).params.projectId()
}
