import {
  RampType,
  InterpolateNotches,
  NoiseType,
  NoiseFragment,
  LayerFragment,
  ConstantFragment,
  RemapFragment,
  DisplaceFragment,
  CompositeFragment,
  RampCreateInput,
  Source,
  Extend,
  TransformFragment,
  TransformOrder,
  JustifyHorizontal,
  JustifyVertical,
  Fit,
  Operation,
} from '@lumn-color/hooks'

// TODO move elsewhere because this is not an operator
export const defaultLayer: Omit<LayerFragment, 'id'> = {
  name: 'Layer',
  resolutionW: 50,
  resolutionH: 50,
  translateX: 0,
  translateY: 0,
  zIndex: 0,
  input: null,
  exp_translateX: null,
  exp_translateY: null,
}

const defaultOperator = {
  resolutionW: 50,
  resolutionH: 50,
}

// TODO Rename keys to OperatorTypes, (default.Ramp, default.Remap, etc.)
export const Ramp: Omit<RampCreateInput, 'layer'> = {
  ...defaultOperator,
  name: 'Ramp',
  positionX: 0,
  positionY: 0,
  type: RampType.Horizontal,
  phase: 0,
  period: 1,
  extendLeft: Extend.Repeat,
  extendRight: Extend.Repeat,
  interpolateNotches: InterpolateNotches.Linear,
  curveTension: 0,
  antiAlias: 1,
  dither: false,
  multiplyRgbByAlpha: false,
  exp_positionX: null,
  exp_positionY: null,
  exp_type: null,
  exp_phase: null,
  exp_period: null,
  exp_extendLeft: null,
  exp_extendRight: null,
  exp_interpolateNotches: null,
  exp_curveTension: null,
  exp_antiAlias: null,
  exp_dither: null,
  exp_multiplyRgbByAlpha: null,
  keys: {
    create: [
      {
        colorR: 0,
        colorG: 0,
        colorB: 0,
        colorA: 1,
        position: 0,
      },
      {
        colorR: 255,
        colorG: 255,
        colorB: 255,
        colorA: 1,
        position: 1,
      },
    ],
  },
}

export const Noise: Omit<NoiseFragment, 'id' | 'keys'> = {
  ...defaultOperator,
  name: 'Noise',
  type: NoiseType.Simplex_3DGpu,
  seed: 1,
  period: 1,
  harmonics: 2,
  harmonicSpread: 2,
  harmonicGain: 0.7,
  exponent: 1,
  amplitude: 0.5,
  offset: 0.5,
  monochrome: true,
  aspectCorrect: true,
  translateX: 0,
  translateY: 0,
  translateZ: 0,
  scaleX: 1,
  scaleY: 1,
  scaleZ: 1,
  rotateX: 0,
  rotateY: 0,
  rotateZ: 0,
  exp_seed: null,
  exp_period: null,
  exp_harmonics: null,
  exp_harmonicSpread: null,
  exp_harmonicGain: null,
  exp_exponent: null,
  exp_amplitude: null,
  exp_offset: null,
  exp_monochrome: null,
  exp_aspectCorrect: null,
  exp_translateX: null,
  exp_translateY: null,
  exp_translateZ: null,
  exp_scaleX: null,
  exp_scaleY: null,
  exp_scaleZ: null,
  exp_rotateX: null,
  exp_rotateY: null,
  exp_rotateZ: null,
}

export const Constant: Omit<ConstantFragment, 'id'> = {
  ...defaultOperator,
  name: 'Constant',
  colorR: 255,
  colorG: 0,
  colorB: 0,
  colorA: 1,
  exp_colorR: null,
  exp_colorG: null,
  exp_colorB: null,
  exp_colorA: null,
}

export const Remap: Omit<RemapFragment, 'id'> = {
  name: 'Remap',
  horizontalSource: Source.Red,
  verticalSource: Source.Green,
  flipHorizontalDirection: false,
  flipVerticalDirection: false,
  extend: Extend.Zero,
  input1: null,
  input2: null,
}

export const Displace: Omit<DisplaceFragment, 'id'> = {
  name: 'Displace',
  horizontalSource: Source.Red,
  verticalSource: Source.Blue,
  sourceMidpointX: 0.5,
  sourceMidpointY: 0.5,
  displaceWeightX: 1,
  displaceWeightY: 1,
  uvWeight: 1,
  offsetX: 0.5,
  offsetY: 0.5,
  offsetWeight: 0,
  extend: Extend.Hold,
  input1: null,
  input2: null,
}

export const Transform: Omit<TransformFragment, 'id'> = {
  name: 'Transform',
  resolutionW: 50,
  resolutionH: 50,
  translateX: 0,
  translateY: 0,
  scaleX: 1,
  scaleY: 1,
  rotate: 0,
  exp_translateX: null,
  exp_translateY: null,
  exp_scaleX: null,
  exp_scaleY: null,
  exp_rotate: null,
  extend: Extend.Zero,
  transformOrder: TransformOrder.ScaleRotateTranslate,
  input1: null,
}

export const Composite: Omit<CompositeFragment, 'id'> = {
  name: 'Composite',
  operation: Operation.Add,
  fit: Fit.Fill,
  justifyHorizontal: JustifyHorizontal.Center,
  justifyVertical: JustifyVertical.Center,
  translateX: 0,
  translateY: 0,
  scaleX: 1,
  scaleY: 1,
  rotate: 0,
  exp_translateX: null,
  exp_translateY: null,
  exp_scaleX: null,
  exp_scaleY: null,
  exp_rotate: null,
  extend: Extend.Zero,
  transformOrder: TransformOrder.ScaleRotateTranslate,
  fixedLayer: 0,
  input1: null,
  input2: null,
}
