// TODO introduce to codegen toolchain
// description: implements proper cache updates and optimistic responses

import {
  createUseCreateOperator,
  createUseDeleteOperator,
} from 'factories/createUseOperator'

import {
  useCreateNoiseMutation,
  useDeleteNoiseMutation,
  NoiseFragmentDoc,
  useCreateConstantMutation,
  useDeleteConstantMutation,
  ConstantFragmentDoc,
  useCreateRampMutation,
  useDeleteRampMutation,
  RampFragmentDoc,
  useCreateDisplaceMutation,
  useDeleteDisplaceMutation,
  DisplaceFragmentDoc,
  useCreateRemapMutation,
  useDeleteRemapMutation,
  RemapFragmentDoc,
  useCreateTransformMutation,
  useDeleteTransformMutation,
  TransformFragmentDoc,
  useCreateCompositeMutation,
  useDeleteCompositeMutation,
  CompositeFragmentDoc,
} from '@lumn-color/hooks'

export const useCreateRamp = createUseCreateOperator({
  mutationHook: useCreateRampMutation,
  __typename: 'Ramp',
  mutationName: 'createRamp',
  layersPath: 'ramps',
  requiredProperties: {
    keys: [
      {
        id: '0',
        __typename: 'RampKey',
        colorR: 0,
        colorG: 0,
        colorB: 0,
        colorA: 1,
        position: 0,
      },
      {
        id: '1',
        __typename: 'RampKey',
        colorR: 255,
        colorG: 255,
        colorB: 255,
        colorA: 1,
        position: 1,
      },
    ],
  },
})

export const useDeleteRamp = createUseDeleteOperator({
  mutationHook: useDeleteRampMutation,
  __typename: 'Ramp',
  mutationName: 'deleteRamp',
  fragmentDoc: RampFragmentDoc,
  fragmentName: 'Ramp',
  layersPath: 'ramps',
})

export const useCreateNoise = createUseCreateOperator({
  mutationHook: useCreateNoiseMutation,
  __typename: 'Noise',
  mutationName: 'createNoise',
  layersPath: 'noises',
})

export const useDeleteNoise = createUseDeleteOperator({
  mutationHook: useDeleteNoiseMutation,
  __typename: 'Noise',
  mutationName: 'deleteNoise',
  fragmentDoc: NoiseFragmentDoc,
  fragmentName: 'Noise',
  layersPath: 'noises',
})

export const useCreateConstant = createUseCreateOperator({
  mutationHook: useCreateConstantMutation,
  __typename: 'Constant',
  mutationName: 'createConstant',
  layersPath: 'constants',
})

export const useDeleteConstant = createUseDeleteOperator({
  mutationHook: useDeleteConstantMutation,
  __typename: 'Constant',
  mutationName: 'deleteConstant',
  fragmentDoc: ConstantFragmentDoc,
  fragmentName: 'Constant',
  layersPath: 'constants',
})

export const useCreateDisplace = createUseCreateOperator({
  mutationHook: useCreateDisplaceMutation,
  __typename: 'Displace',
  mutationName: 'createDisplace',
  layersPath: 'displaces',
})

export const useDeleteDisplace = createUseDeleteOperator({
  mutationHook: useDeleteDisplaceMutation,
  __typename: 'Displace',
  mutationName: 'deleteDisplace',
  fragmentDoc: DisplaceFragmentDoc,
  fragmentName: 'Displace',
  layersPath: 'displaces',
})

export const useCreateRemap = createUseCreateOperator({
  mutationHook: useCreateRemapMutation,
  __typename: 'Remap',
  mutationName: 'createRemap',
  layersPath: 'remaps',
})

export const useDeleteRemap = createUseDeleteOperator({
  mutationHook: useDeleteRemapMutation,
  __typename: 'Remap',
  mutationName: 'deleteRemap',
  fragmentDoc: RemapFragmentDoc,
  fragmentName: 'Remap',
  layersPath: 'remaps',
})

export const useCreateTransform = createUseCreateOperator({
  mutationHook: useCreateTransformMutation,
  __typename: 'Transform',
  mutationName: 'createTransform',
  layersPath: 'transforms',
})

export const useDeleteTransform = createUseDeleteOperator({
  mutationHook: useDeleteTransformMutation,
  __typename: 'Transform',
  mutationName: 'deleteTransform',
  fragmentDoc: TransformFragmentDoc,
  fragmentName: 'Transform',
  layersPath: 'transforms',
})

export const useCreateComposite = createUseCreateOperator({
  mutationHook: useCreateCompositeMutation,
  __typename: 'Composite',
  mutationName: 'createComposite',
  layersPath: 'composites',
})

export const useDeleteComposite = createUseDeleteOperator({
  mutationHook: useDeleteCompositeMutation,
  __typename: 'Composite',
  mutationName: 'deleteComposite',
  fragmentDoc: CompositeFragmentDoc,
  fragmentName: 'Composite',
  layersPath: 'composites',
})
