import * as React from 'react'
import { Switch } from 'antd'
import { Field as IField, Model } from 'types/model'
import { useUpdateFieldSimple } from 'hooks/useUpdateFieldSimple'
import { Label } from './Label'

type FieldUpdater = ReturnType<typeof useUpdateFieldSimple>

type Props<M extends Model, F extends IField<M>> = {
  label: string
  fieldUpdaters: FieldUpdater[]
}

export const SwitchField = React.memo(function<
  M extends Model,
  F extends IField<M>
>(props: Props<M, F>) {
  return (
    <div style={{ marginBottom: 8 }}>
      <Label title={props.label} syncing={syncing()} />
      <div style={{ display: 'flex' }}>{renderInputs()}</div>
    </div>
  )

  function renderInputs() {
    return props.fieldUpdaters.map((fieldUpdater, index) => {
      return (
        <Switch
          key={index}
          size="small"
          checked={fieldUpdater.value}
          onChange={value => fieldUpdater.setValue(value)}
        />
      )
    })
  }

  function syncing() {
    return props.fieldUpdaters.some(_ => _.syncing)
  }
})
