import { useCallback, useEffect, useRef, useState } from 'react'
import { Data, Model, Field, UseGetQuery, Update } from 'types/model'

/*
Description:
  The purpose of the useUpdateFieldSimple hook is to create per-field update capabilities
  based on a timeout. The hook handles data fetching for the component.

Parameters:
  id: id of entity
  field: field name of entity to observe and update
  queryName: query name, used to extract contents of useGetModelQuery
  useGetQuery: autogenerated query hook for the model  
  update: update function for model, fn sig: (id, data) => void
  timeout?: timeout used to debounce updates
 */

const wait = 300

export type UpdateFieldResult<M extends Model, F extends Field<M>> = {
  value: M[F]
  setValue: (value: M[F]) => void
  syncing: boolean
  onValueCallback: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export function useUpdateFieldSimple<
  M extends Model,
  F extends Field<M>
>(params: {
  id: string
  field: F
  useGetQuery: UseGetQuery<M>
  queryName: string
  update: Update<M>
  timeout?: number
}): UpdateFieldResult<M, F> {
  const result = params.useGetQuery({
    variables: {
      where: {
        id: params.id,
      },
    },
    fetchPolicy: 'cache-only',
  })

  const cacheValue = (result.data &&
    result.data[params.queryName] &&
    result.data[params.queryName][params.field]) as M[F]

  const [value, setValue] = useState<Data<M>[F]>(cacheValue)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const syncing = value !== cacheValue

  useEffect(() => {
    setValue(cacheValue)
  }, [cacheValue])

  useEffect(() => {
    clearTimeout(timeoutRef.current!)
    if (value !== cacheValue) {
      timeoutRef.current = setTimeout(
        async () => {
          // @ts-ignore FIXME
          await params.update(params.id, {
            [params.field]: value,
          })
        },
        params.timeout !== undefined ? params.timeout : wait,
      )
      return () => {
        clearTimeout(timeoutRef.current!)
      }
    }
  }, [params, value, cacheValue])

  const onValueCallback = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.currentTarget.value as any)
    },
    [],
  )

  return {
    value,
    setValue,
    syncing,
    onValueCallback,
  }
}
