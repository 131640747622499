import { Icon, Modal } from 'antd'
import React, { useCallback } from 'react'
import { useCreateLayer } from 'api'
import { defaultLayer } from 'constants/defaults'
import { useDownloadProject } from 'hooks/useDownloadProject'
// import { useProjectContext } from 'components/ProjectMasterDetail'

const { confirm } = Modal

// TODO fix memory bug with context! probably related to antd collapse.

export function ProjectSettings({ projectId }: { projectId: string }) {
  const createLayer = useCreateLayer()
  const downloadProject = useDownloadProject()

  const createProjectLayer = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      confirm({
        title: `Do you want to create a new layer?`,
        okText: 'Yes',
        cancelText: 'No',
        icon: null,
        onOk() {
          createLayer({
            ...defaultLayer,
            project: {
              connect: {
                id: projectId,
              },
            },
          })
        },
      })
    },
    [projectId, createLayer],
  )

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: 8 }} />
      <Icon type="download" onClick={downloadProject} />
      <div style={{ width: 8 }} />
      <Icon type="plus" onClick={createProjectLayer} />
    </div>
  )
}

// const { showCanvas, toggleShowCanvas } = useProjectContext()
// <Icon
// type={showCanvas ? 'eye' : 'eye-invisible'}
// onClick={toggleShowCanvas}
// />
