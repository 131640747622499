import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Modal, Icon, Card } from 'antd'
import { Project } from '@lumn-color/hooks'
import { useDeleteProject, useCopyProject } from 'api'

const { confirm } = Modal

type Props = {
  project: Project
}

export function ProjectCard({ project }: Props) {
  const deleteProject = useDeleteProject()
  const { prepareCopyProject, copyProject } = useCopyProject({
    projectId: project.id,
  })

  const onDeleteProject = useCallback(
    () => {
      confirm({
        title: `Are you sure you want to delete project ${project.name}?`,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          deleteProject(project.id)
        },
      })
    },
    [deleteProject, project.id, project.name],
  )

  const onCopyProject = useCallback(
    () => {
      prepareCopyProject()
      confirm({
        title: `Are you sure you want to duplicate project ${project.name}?`,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          // TODO wait for project to be fetched, then copy project
          copyProject()
        },
      })
    },
    [copyProject, prepareCopyProject, project.name],
  )

  return <Card actions={actions()} title={project.name} />

  function actions() {
    return [
      <Icon type="delete" onClick={onDeleteProject} />,
      <Icon type="copy" onClick={onCopyProject} />,
      <Link to={`/projects/${project.id}`}>
        <Icon type="eye" />
      </Link>,
    ]
  }
}
