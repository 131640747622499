import * as React from 'react'
import { Typography, Icon } from 'antd'

const { Text } = Typography

type Props = {
  title: string
  syncing?: boolean
}

export function Label(props: Props) {
  return (
    <Text type="secondary">
      {props.title}
      {props.syncing && (
        <Icon
          style={{
            marginLeft: 5,
          }}
          type="sync"
          spin
        />
      )}
    </Text>
  )
}
