import { Icon, Modal } from 'antd'
import { useCopyLayer, useDeleteLayer } from 'api'
import React, { useCallback } from 'react'
import { useRouterProjectId } from 'router'

const { confirm } = Modal

export function LayerSettings({ layerId }: { layerId: string }) {
  const projectId = useRouterProjectId()!
  const deleteLayer = useDeleteLayer({ projectId })
  const copyLayer = useCopyLayer({ layerId, projectId })
  const onDeleteLayer = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      confirm({
        title: `Are you sure you want to delete this layer?`,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          deleteLayer(layerId)
        },
      })
    },
    [deleteLayer, layerId],
  )
  const onCopyLayer = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      confirm({
        title: `Are you sure you want to duplicate this layer?`,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          copyLayer()
        },
      })
    },
    [copyLayer],
  )

  return (
    <div style={{ display: 'flex' }}>
      <Icon type="copy" onClick={onCopyLayer} />
      <div style={{ width: 8 }} />
      <Icon type="delete" onClick={onDeleteLayer} />
    </div>
  )
}
