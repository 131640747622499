import React from 'react'
import { Menu, Icon, Dropdown, Button } from 'antd'
import { Link } from 'react-router-dom'
import { useListProjectsQuery, Viewer } from '@lumn-color/hooks'
import { useUpdateViewer } from 'api'
import { oc } from 'ts-optchain'
import { Label } from 'components/Field/Label'

type Props = {
  viewer: Viewer
}

export function ViewerProjectDropdown({ viewer }: Props) {
  const projectsQuery = useListProjectsQuery()
  const updateViewer = useUpdateViewer()

  const projects = oc(projectsQuery).data.projects([])

  const menu = (
    <Menu>
      {projects
        .map(project => (
          <Menu.Item key={project.id} onClick={() => selectProject(project.id)}>
            {project.name}
          </Menu.Item>
        ))
        .concat(
          <Menu.Item key="none" onClick={deselectProject}>
            None
          </Menu.Item>,
        )}
    </Menu>
  )

  if (!viewer) return null

  const dropdownButtonText = viewer.project
    ? viewer.project.name
    : 'No Project Selected'

  const dropdownButtonLink = viewer.project
    ? `/projects/${viewer.project.id}`
    : '/viewers'

  return (
    <>
      <div style={{ display: 'block' }}>
        <Label title="Project" />
      </div>
      <div style={{ display: 'flex' }}>
        <Dropdown overlay={menu} placement="bottomLeft">
          <div style={{ width: 'calc(50% + 8px)' }}>
            <Button size="small" block>
              {dropdownButtonText}
            </Button>
          </div>
        </Dropdown>
        {projectLink()}
      </div>
    </>
  )

  function projectLink() {
    if (!viewer.project) return null
    return (
      <div style={{ marginLeft: '8px' }}>
        <Link to={dropdownButtonLink}>
          <Icon type="eye" />
        </Link>
      </div>
    )
  }

  function selectProject(projectId: string | null) {
    updateViewer(viewer.id, {
      project: {
        connect: {
          id: projectId,
        },
      },
    })
  }

  function deselectProject() {
    if (!viewer.project) return

    updateViewer(viewer.id, {
      project: {
        disconnect: true,
      },
    })
  }
}
