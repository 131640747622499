import {
  ConstantFragment,
  ConstantFragmentDoc,
  useGetConstantQuery,
  useUpdateConstantMutation,
} from '@lumn-color/hooks'
import { Field, FieldProps } from 'components/Field'
import { createUpdateFieldHook } from 'hooks/useUpdateField'
import * as React from 'react'
import { Omit } from 'utils'

const fields: Omit<EntityFieldProps, 'id'>[] = [
  {
    label: 'Name',
    fields: ['name'],
  },
  {
    label: 'Resolution',
    type: 'number',
    fields: ['resolutionW', 'resolutionH'],
  },
  {
    label: 'Color',
    type: 'color',
    expressions: true,
    fields: ['colorR', 'colorG', 'colorB', 'colorA'],
  },
]

const useUpdateField = createUpdateFieldHook<ConstantFragment>({
  __typename: 'Constant',
  fragmentDoc: ConstantFragmentDoc,
  queryName: 'constant',
  mutationName: 'updateConstant',
  useGetQuery: useGetConstantQuery,
  useUpdateMutation: useUpdateConstantMutation,
})

type EntityFieldProps = Omit<FieldProps<ConstantFragment>, 'useUpdateField'>

function EntityField(props: EntityFieldProps) {
  return <Field<ConstantFragment> {...props} useUpdateField={useUpdateField} />
}

export const Constant = React.memo(function EntityView({ id }: { id: string }) {
  return <>{entityFields()}</>

  function entityFields() {
    return fields.map((field, index) => (
      <EntityField key={index} id={id} {...field} />
    ))
  }
})
