import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ProjectBreadcrumbs } from './ProjectBreadcrumbs'
import { ViewerBreadcrumbs } from './ViewerBreadcrumbs'

export function AppBreadcrumbs() {
  return (
    <Switch>
      <Route path="/projects" exact component={ProjectBreadcrumbs} />
      <Route path="/projects/:projectId" exact component={ProjectBreadcrumbs} />
      <Route
        path="/projects/:projectId/layers/:layerId"
        exact
        component={ProjectBreadcrumbs}
      />
      <Route path="/viewers" exact component={ViewerBreadcrumbs} />
      <Route path="/viewers/:viewerId" exact component={ViewerBreadcrumbs} />
    </Switch>
  )
}
