import * as React from 'react'
import { Input, InputNumber } from 'antd'
import { Label } from './Label'
import { Field as IField, Model } from 'types/model'
import { useUpdateFieldSimple } from 'hooks/useUpdateFieldSimple'

type FieldUpdater = ReturnType<typeof useUpdateFieldSimple>

type Props<M extends Model, F extends IField<M>> = {
  label: string
  stacked?: boolean
  type?: 'string' | 'number'
  fieldUpdaters: FieldUpdater[]
}

export const SimpleField = React.memo(function<
  M extends Model,
  F extends IField<M>
>(props: Props<M, F>) {
  return (
    <div style={{ marginBottom: 8 }}>
      <Label title={props.label} syncing={syncing()} />
      <div style={{ display: props.stacked ? 'block' : 'flex' }}>
        {renderInputs()}
      </div>
    </div>
  )

  function renderInputs() {
    return props.fieldUpdaters.map(renderInput)
  }

  function renderInput(fieldUpdater: FieldUpdater, index: number) {
    return (
      <React.Fragment key={index}>
        {index !== 0 && (
          <div
            style={{
              width: props.stacked ? 0 : 8,
              height: props.stacked ? 8 : 0,
            }}
          />
        )}
        {input()}
      </React.Fragment>
    )

    function input() {
      if (props.type === 'number') {
        return (
          <InputNumber
            size="small"
            style={{
              flex: 0.5,
            }}
            value={fieldUpdater.value as number}
            onChange={_ => fieldUpdater.setValue(Number(_))}
          />
        )
      }
      return (
        <Input
          size="small"
          style={{ flex: 0.5 }}
          value={fieldUpdater.value as string}
          onChange={fieldUpdater.onValueCallback}
        />
      )
    }
  }

  function syncing() {
    return props.fieldUpdaters.some(_ => _.syncing)
  }
})
