import * as React from 'react'
import { Menu, Dropdown } from 'antd'

type Item = {
  title: string
  onClick(): void
}

type Props = {
  items: Item[]
  children: React.ReactNode
}

export const DropdownMenu = React.memo((props: Props) => {
  return (
    <Dropdown trigger={['click']} overlay={renderMenu()}>
      <div
        onClick={e => {
          e.preventDefault()
        }}
      >
        {props.children}
      </div>
    </Dropdown>
  )

  function renderMenu() {
    return (
      <Menu>
        {props.items.map((item, index) => (
          <Menu.Item
            key={index}
            onClick={click => {
              click.domEvent.stopPropagation()
              item.onClick()
            }}
          >
            {item.title}
          </Menu.Item>
        ))}
      </Menu>
    )
  }
})
