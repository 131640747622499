import { Source, Extend } from '@lumn-color/hooks'

export const sourceOptions = Object.values(Source).map(_ => ({
  label: _,
  value: _,
}))

export const extendOptions = Object.values(Extend).map(_ => ({
  label: _,
  value: _,
}))
