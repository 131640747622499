import {
  LayerFragment,
  LayerFragmentDoc,
  useGetLayerQuery,
  useUpdateLayerMutation,
} from '@lumn-color/hooks'
import { Field, FieldProps } from 'components/Field'
import { createUpdateFieldHook } from 'hooks/useUpdateField'
import * as React from 'react'
import { Omit } from 'utils'
import { OperatorList } from './OperatorList'

export const LayerView = React.memo(function({ layerId }: { layerId: string }) {
  return (
    <>
      {renderFields()}
      <OperatorList layerId={layerId} />
    </>
  )

  function renderFields() {
    return fields.map((field, index) => (
      <EntityField key={index} id={layerId} {...field} />
    ))
  }
})

const useUpdateLayerField = createUpdateFieldHook<LayerFragment>({
  __typename: 'Layer',
  fragmentDoc: LayerFragmentDoc,
  queryName: 'layer',
  mutationName: 'updateLayer',
  useGetQuery: useGetLayerQuery,
  useUpdateMutation: useUpdateLayerMutation,
})

type EntityFieldProps = Omit<FieldProps<LayerFragment>, 'useUpdateField'>

function EntityField(props: EntityFieldProps) {
  return (
    <Field<LayerFragment> {...props} useUpdateField={useUpdateLayerField} />
  )
}

const fields: Omit<EntityFieldProps, 'id'>[] = [
  {
    label: 'Name',
    fields: ['name'],
  },
  {
    label: 'Resolution',
    type: 'number',
    fields: ['resolutionW', 'resolutionH'],
  },
  {
    label: 'Translate',
    type: 'number',
    fields: ['translateX', 'translateY'],
  },
  {
    label: 'Z Index',
    type: 'number',
    fields: ['zIndex'],
  },
]
