import React from 'react'
import { SelectField } from './SelectField'
import { useUpdateFieldSimple } from 'hooks/useUpdateFieldSimple'
import { useRouterLayer } from 'router'
import { useLayerOperators } from 'api'

type FieldUpdater = ReturnType<typeof useUpdateFieldSimple>

type Props = {
  id: string
  label: string
  fieldUpdaters: FieldUpdater[]
}
export function SelectOperatorField(props: Props) {
  const { layer } = useRouterLayer() // FIXME this is not always true. should pass layer id?
  const operators = useLayerOperators({ layerId: layer && layer.id })

  return (
    <SelectField
      {...props}
      options={options()}
      fieldUpdaters={props.fieldUpdaters}
    />
  )

  function options() {
    return operators
      .filter(_ => _.id !== props.id)
      .map(_ => ({
        label: _.name || _.__typename!,
        value: `${_.__typename}:${_.id}`,
      }))
  }
}
