import React from 'react'
import { Icon, Modal } from 'antd'
import { useDeleteOperator, useCopyOperator, useUpdateLayer } from 'api'
import { Operator, OperatorType } from 'constants/operators'

const { confirm } = Modal

export function OperatorExtra(props: Props) {
  const { operator, layerId, selected } = props
  const updateLayer = useUpdateLayer()
  const deleteOperator = useDeleteOperator({ layerId })
  const copyOperator = useCopyOperator({
    layerId,
    type: operator.__typename as OperatorType,
    operatorId: operator.id,
  })

  return (
    <>
      <Icon type="delete" style={{ marginRight: 8 }} onClick={onDelete} />
      <Icon type="copy" style={{ marginRight: 8 }} onClick={onCopy} />
      <Icon
        type="eye"
        theme={selected ? 'twoTone' : 'outlined'}
        style={{
          opacity: selected ? 1 : 0.25,
        }}
        onClick={onSelect}
      />
    </>
  )

  function onCopy(event: React.MouseEvent) {
    event.stopPropagation()
    confirm({
      title: `Are you sure you want to copy ${operator.name}?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        copyOperator()
      },
    })
  }

  function onDelete(event: React.MouseEvent) {
    event.stopPropagation()
    confirm({
      title: `Are you sure you want to delete ${operator.name}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteOperator({
          id: operator.id,
          type: operator.__typename as OperatorType,
        })
      },
    })
  }

  function onSelect(event: React.MouseEvent) {
    event.stopPropagation()
    const input = `${operator.__typename}:${operator.id}`
    updateLayer(layerId, {
      input: selected ? null : input,
    })
  }
}

type Props = {
  operator: Operator
  layerId: string
  selected: boolean
}
