import React, { useState, useCallback, useRef, useLayoutEffect } from 'react'
import useMount from 'react-use/lib/useMount'
import useUnmount from 'react-use/lib/useUnmount'
import * as ReactDOM from 'react-dom'
import './ColorStop.css'

type Stop = {
  id: string
  color: string
  pos: number
  isActive: boolean
  pointX?: number
}

type Props = {
  stop: Stop
  limits: {
    min: number
    max: number
    drop: number
  }
  onPosChange(args: any): void
  onActivate(id: string): void
  onDeleteColor(id: string): void
}

export default function ColorStop(props: Props) {
  const [dragging, setDragging] = useState(false)
  const [posStart, setPosStart] = useState(0)
  const ref = useRef<HTMLDivElement | null>()

  const handleMouseUp = useEventCallback(() => {
    setDragging(false)
    document.removeEventListener('touchstart', handleMouseUp, false)
    document.removeEventListener('mousedown', handleMouseUp, false)
    return false
  })

  const handleMouseMove = useEventCallback((event: any) => {
    if (!dragging) {
      document.removeEventListener('touchmove', handleMouseMove, false)
      document.removeEventListener('mousemove', handleMouseMove, false)
      return false
    }

    const {
      limits,
      onDeleteColor,
      onPosChange,
      stop: { id, pos },
    } = props

    const domNode = ReactDOM.findDOMNode(ref.current)
    if (domNode) {
      const pointY = event.clientY || event.touches[0].clientY
      const top = (domNode as Element).getBoundingClientRect().top
      const heightDiff = Math.abs(pointY - top)
      if (heightDiff > 50) {
        handleMouseUp()
        onDeleteColor(id)
        return false
      }
    }

    // limit movements
    const offset = pos - posStart
    const pointX = event.clientX || event.touches[0].clientX
    const newPos = Math.max(Math.min(offset + pointX, limits.max), limits.min)
    setPosStart(newPos - offset)
    onPosChange({ id, pos: newPos })

    return false
  })

  const activate = useCallback(
    (pointX: number) => {
      setPosStart(pointX)
      setDragging(true)
      props.onActivate(props.stop.id)
      document.addEventListener('touchmove', handleMouseMove, false)
      document.addEventListener('touchend', handleMouseUp, false)
      document.addEventListener('mousemove', handleMouseMove, false)
      document.addEventListener('mouseup', handleMouseUp, false)
    },
    [props, setPosStart, setDragging, handleMouseMove, handleMouseUp],
  )

  const handleMouseDown = useCallback(
    (e: any) => {
      e.preventDefault()
      e.stopPropagation()
      if (!e.button) activate(e.clientX)
      return false
    },
    [activate],
  )

  useMount(() => {
    if (props.stop.pointX) {
      activate(props.stop.pointX)
    }
  })

  useUnmount(() => {
    document.removeEventListener('touchmove', handleMouseMove, false)
    document.removeEventListener('touchend', handleMouseUp, false)
    document.removeEventListener('mousemove', handleMouseMove, false)
    document.removeEventListener('mouseup', handleMouseUp, false)
  })

  return (
    <div
      ref={_ => (ref.current = _)}
      className={props.stop.isActive ? 'cs active' : 'cs'}
      style={{ left: props.stop.pos }}
      onTouchStart={handleMouseDown}
      onMouseDown={handleMouseDown}
    >
      <div style={{ backgroundColor: props.stop.color }} />
    </div>
  )
}

function useEventCallback(fn: any) {
  let ref = useRef<(e: any) => void>()
  useLayoutEffect(() => {
    ref.current = fn
  })
  return useCallback((e?: any) => ref.current && ref.current(e), [])
}
