import { RampKeyFragment } from '@lumn-color/hooks'
import { isEqual, keyBy } from 'lodash'

type Return = {
  created: RampKeyFragment[]
  updated: RampKeyFragment[]
  deleted: RampKeyFragment[]
}

export function rampKeysDifference({
  prev,
  curr,
}: {
  prev: RampKeyFragment[]
  curr: RampKeyFragment[]
}): Return {
  const prevDict = keyBy(prev, 'id')
  const currDict = keyBy(curr, 'id')
  const deleted = prev.filter(_ => !currDict[_.id])
  const created = curr.filter(_ => !prevDict[_.id])
  const updated = curr.filter(_ => {
    const __ = prevDict[_.id]
    if (!__) return false
    return !isEqual(_, __)
  })
  return {
    created,
    updated,
    deleted,
  }
}
