import {
  useGetViewerQuery,
  useUpdateViewerMutation,
  Viewer,
  ViewerFragment,
  ViewerFragmentDoc,
} from '@lumn-color/hooks'
import { Field, FieldProps } from 'components/Field'
import { createUpdateFieldHook } from 'hooks/useUpdateField'
import * as React from 'react'
import { Omit } from 'utils'
import { ViewerProjectDropdown } from './ViewerProjectDropdown'

export const ViewerView = React.memo(({ viewer }: { viewer: Viewer }) => {
  return (
    <>
      {renderFields()}
      <ViewerProjectDropdown viewer={viewer} />
    </>
  )

  function renderFields() {
    return fields.map((field, index) => (
      <EntityField key={index} id={viewer.id} {...field} />
    ))
  }
})

const useUpdateViewerField = createUpdateFieldHook<ViewerFragment>({
  __typename: 'Viewer',
  fragmentDoc: ViewerFragmentDoc,
  queryName: 'viewer',
  mutationName: 'updateViewer',
  useGetQuery: useGetViewerQuery,
  // @ts-ignore FIXME
  useUpdateMutation: useUpdateViewerMutation,
})

type EntityFieldProps = Omit<FieldProps<ViewerFragment>, 'useUpdateField'>

function EntityField(props: EntityFieldProps) {
  return (
    <Field<ViewerFragment> {...props} useUpdateField={useUpdateViewerField} />
  )
}

const fields: Omit<EntityFieldProps, 'id'>[] = [
  {
    label: 'Name',
    fields: ['name'],
  },
]
