import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
import './index.css'
import './antd.css'
import App from './App'
import * as serviceWorker from 'setup/serviceWorker'

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()
