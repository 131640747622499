import * as React from 'react'
import { Popover } from 'antd'
import { ChromePicker, ColorResult, RGBColor } from 'react-color'

type Props = {
  color: RGBColor
  onChange(color: ColorResult): void
}

export function ColorPicker(props: Props) {
  const [visible, setVisible] = React.useState(false)

  return (
    <Popover
      content={<ChromePicker {...props} />}
      title="Color"
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
    >
      <div style={styles.swatch}>
        <div
          style={{
            ...styles.color,
            background: backgroundColor(props.color),
          }}
        />
      </div>
    </Popover>
  )

  function backgroundColor({ r, g, b, a }: RGBColor) {
    return `rgba(${r}, ${g}, ${b}, ${a})`
  }
}

const styles = {
  swatch: {
    marginTop: 5,
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  color: {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
  },
} as const
