import {
  RampFragment,
  RampFragmentDoc,
  useGetRampQuery,
  useUpdateRampMutation,
  InterpolateNotches,
  RampType,
} from '@lumn-color/hooks'
import { Field, FieldProps } from 'components/Field'
import { GradientField } from 'components/GradientField'
import { createUpdateFieldHook } from 'hooks/useUpdateField'
import * as React from 'react'
import { Omit } from 'utils'
import { extendOptions } from 'constants/fields'

const interpolateNotchesOptions = Object.values(InterpolateNotches).map(
  value => ({
    label: value.replace(/_/g, ' '),
    value,
  }),
)

const rampTypeOptions = Object.values(RampType).map(value => ({
  label: value.replace(/_/g, ' '),
  value,
}))

const fields: Omit<EntityFieldProps, 'id'>[] = [
  {
    label: 'Name',
    fields: ['name'],
  },
  {
    label: 'Resolution',
    type: 'number',
    fields: ['resolutionW', 'resolutionH'],
  },
  {
    label: 'Type',
    wait: 0,
    type: 'enum',
    options: rampTypeOptions,
    fields: ['type'],
  },
  {
    label: 'Phase',
    type: 'number',
    expressions: true,
    fields: ['phase'],
  },
  {
    label: 'Period',
    type: 'number',
    fields: ['period'],
  },
  {
    label: 'Position',
    type: 'number',
    fields: ['positionX', 'positionY'],
  },
  {
    label: 'Extend Left',
    wait: 0,
    type: 'enum',
    options: extendOptions,
    fields: ['extendLeft'],
  },
  {
    label: 'Extend Right',
    wait: 0,
    type: 'enum',
    options: extendOptions,
    fields: ['extendRight'],
  },
  {
    label: 'Interpolate Notches',
    wait: 0,
    type: 'enum',
    options: interpolateNotchesOptions,
    fields: ['interpolateNotches'],
  },
  {
    label: 'Curve Tension',
    type: 'number',
    fields: ['curveTension'],
  },
  {
    label: 'Anti Alias',
    type: 'number',
    fields: ['antiAlias'],
  },
  {
    label: 'Dither',
    wait: 0,
    type: 'boolean',
    fields: ['dither'],
  },
  {
    label: 'Multiply RGB by Alpha',
    wait: 0,
    type: 'boolean',
    fields: ['multiplyRgbByAlpha'],
  },
]

const useUpdateField = createUpdateFieldHook<RampFragment>({
  __typename: 'Ramp',
  fragmentDoc: RampFragmentDoc,
  queryName: 'ramp',
  mutationName: 'updateRamp',
  useGetQuery: useGetRampQuery,
  // @ts-ignore FIXME
  useUpdateMutation: useUpdateRampMutation,
})

type EntityFieldProps = Omit<FieldProps<RampFragment>, 'useUpdateField'>

function EntityField(props: EntityFieldProps) {
  return <Field<RampFragment> {...props} useUpdateField={useUpdateField} />
}

export const Ramp = React.memo(function EntityView({ id }: { id: string }) {
  return (
    <>
      <GradientField rampId={id} />
      {entityFields()}
    </>
  )

  function entityFields() {
    return fields.map((field, index) => (
      <EntityField key={index} id={id} {...field} />
    ))
  }
})
