import {
  NoiseFragment,
  NoiseFragmentDoc,
  useGetNoiseQuery,
  useUpdateNoiseMutation,
} from '@lumn-color/hooks'
import { NoiseType } from '@lumn-color/hooks'
import { Field, FieldProps } from 'components/Field'
import { createUpdateFieldHook } from 'hooks/useUpdateField'
import * as React from 'react'
import { Omit } from 'utils'

const noiseTypeOptions = Object.values(NoiseType).map(value => ({
  label: value.replace(/_/g, ' '),
  value,
}))

const fields: Omit<EntityFieldProps, 'id'>[] = [
  {
    label: 'Name',
    fields: ['name'],
  },
  {
    label: 'Resolution',
    type: 'number',
    fields: ['resolutionW', 'resolutionH'],
  },
  {
    label: 'Type',
    type: 'enum',
    wait: 0,
    options: noiseTypeOptions,
    fields: ['type'],
  },
  {
    label: 'Amplitude',
    type: 'number',
    fields: ['amplitude'],
  },
  {
    label: 'Aspect Correct',
    wait: 0,
    type: 'boolean',
    fields: ['aspectCorrect'],
  },
  {
    label: 'Exponent',
    type: 'number',
    fields: ['exponent'],
  },
  {
    label: 'Harmonic Gain',
    type: 'number',
    fields: ['harmonicGain'],
  },
  {
    label: 'Monochrome',
    wait: 0,
    type: 'boolean',
    fields: ['monochrome'],
  },
  {
    label: 'Offset',
    expressions: true,
    type: 'number',
    fields: ['offset'],
  },
  {
    label: 'Period',
    type: 'number',
    fields: ['period'],
  },
  {
    label: 'Seed',
    type: 'number',
    fields: ['seed'],
  },
  {
    label: 'Rotate',
    type: 'number',
    expressions: true,
    fields: ['rotateX', 'rotateY', 'rotateZ'],
  },
  {
    label: 'Translate',
    type: 'number',
    fields: ['translateX', 'translateY', 'translateZ'],
    expressions: true,
  },
  {
    label: 'Scale',
    type: 'number',
    fields: ['scaleX', 'scaleY', 'scaleZ'],
    expressions: true,
  },
]

const useUpdateField = createUpdateFieldHook<NoiseFragment>({
  __typename: 'Noise',
  fragmentDoc: NoiseFragmentDoc,
  queryName: 'noise',
  mutationName: 'updateNoise',
  useGetQuery: useGetNoiseQuery,
  useUpdateMutation: useUpdateNoiseMutation,
})

type EntityFieldProps = Omit<FieldProps<NoiseFragment>, 'useUpdateField'>

const EntityField = function(props: EntityFieldProps) {
  return <Field<NoiseFragment> {...props} useUpdateField={useUpdateField} />
}

export const Noise = React.memo(function({ id }: { id: string }) {
  return <>{entityFields()}</>

  function entityFields() {
    return fields.map((field, index) => (
      <EntityField key={index} id={id} {...field} />
    ))
  }
})
