import {
  FullLayerFragment,
  FullLayerFragmentDoc,
  FullProjectFragment,
  FullProjectFragmentDoc,
  LayerFragment,
  LayerFragmentDoc,
  useCreateLayerMutation,
  useDeleteLayerMutation,
  useUpdateLayerMutation,
} from '@lumn-color/hooks'
import { omit } from 'lodash'
import { useUpdate } from 'hooks/useUpdate'
import produce from 'immer'
import { useCallback } from 'react'
import { useApolloClient } from 'react-apollo-hooks'
import { autoId, MutationHookVariablesData } from 'utils'
import { operatorKeys } from 'constants/operators'

export function useDeleteLayer({ projectId }: { projectId: string }) {
  const client = useApolloClient()
  const deleteLayer = useDeleteLayerMutation()
  const onDelete = useCallback(
    (layerId: string) =>
      deleteLayer({
        variables: {
          where: {
            id: layerId,
          },
        },
        optimisticResponse() {
          return {
            __typename: 'Mutation',
            deleteLayer: client.cache.readFragment<FullLayerFragment>({
              id: `Layer:${layerId}`,
              fragment: FullLayerFragmentDoc,
              fragmentName: 'FullLayer',
            })!,
          }
        },
        update(proxy, mutationResult) {
          const options = {
            fragment: FullProjectFragmentDoc,
            fragmentName: 'FullProject',
            id: `Project:${projectId}`,
          }
          const fragment = proxy.readFragment<FullProjectFragment>(options)!
          proxy.writeFragment({
            ...options,
            data: produce(fragment, draft => {
              if (!draft.layers) return
              const index = draft.layers.findIndex(_ => _.id === layerId)
              if (index !== -1) draft.layers.splice(index, 1)
            }),
          })
        },
      }),
    [projectId, deleteLayer, client.cache],
  )
  return onDelete
}

export function useUpdateLayer() {
  return useUpdate<LayerFragment>({
    __typename: 'Layer',
    useUpdateMutation: useUpdateLayerMutation,
    mutationName: 'updateLayer',
    fragmentDoc: LayerFragmentDoc,
  })
}

export function useCreateLayer() {
  const create = useCreateLayerMutation()
  return useCallback(
    (data: MutationHookVariablesData<typeof useCreateLayerMutation>) => {
      const variables = {
        data: {
          id: autoId(),
          ...data,
        },
      }
      return create({
        variables,
        optimisticResponse: {
          __typename: 'Mutation',
          createLayer: {
            __typename: 'Layer',
            ...omit(variables.data, operatorKeys),
          },
        },
        update(proxy, result) {
          const fragmentOptions = {
            fragment: FullProjectFragmentDoc,
            fragmentName: 'FullProject',
            id: `Project:${variables.data.project.connect!.id!}`,
          }
          const fragment = proxy.readFragment<FullProjectFragment>(
            fragmentOptions,
          )!
          proxy.writeFragment({
            ...fragmentOptions,
            data: produce(fragment, draft => {
              if (!draft.layers) draft.layers = []
              draft.layers.push({
                ...emptyOperators,
                ...result.data!.createLayer,
              })
            }),
          })
        },
      })
    },
    [create],
  )
}

type EmptyOperators = { [key in typeof operatorKeys[number]]: [] }

const emptyOperators = operatorKeys.reduce(
  (acc, opKey) => ({
    ...acc,
    [opKey]: [],
  }),
  {},
) as EmptyOperators
