import { useGetFullProjectQuery } from '@lumn-color/hooks'
import { oc } from 'ts-optchain'

export function useProjectLayers(projectId: string | null | undefined) {
  const { data } = useGetFullProjectQuery({
    skip: !projectId,
    variables: {
      where: { id: projectId },
    },
  })

  return oc(data).project.layers([])
}
