import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo-hooks'
import { client } from 'setup/apolloClient'
import { AppLayout } from 'components/AppLayout'

export default function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <AppLayout />
      </Router>
    </ApolloProvider>
  )
}
