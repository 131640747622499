import * as defaultValues from 'constants/defaults'
import { OperatorType } from 'constants/operators'
import { useCallback } from 'react'
import * as useOperator from './useOperator'

export function useCreateOperator({ layerId }: { layerId: string }) {
  const createOperatorMap: any = Object.keys(OperatorType).reduce(
    (acc, type) => {
      const createOperator = (useOperator as any)[
        `useCreate${type as OperatorType}`
      ]()
      return {
        ...acc,
        [type]: createOperator,
      }
    },
    {},
  )

  const createOperator = useCallback(
    (type: OperatorType) => {
      const args = {
        layer: {
          connect: {
            id: layerId,
          },
        },
      }
      const defaultValue = defaultValues[type]
      return getCreate()({ ...args, ...defaultValue })
      function getCreate() {
        return createOperatorMap[type]
      }
    },
    [layerId, createOperatorMap],
  )

  return createOperator
}

export function useDeleteOperator({ layerId }: { layerId: string }) {
  const deleteOperatorMap: any = Object.keys(OperatorType).reduce(
    (acc, type) => {
      const deleteOperator = (useOperator as any)[
        `useDelete${type as OperatorType}`
      ]()
      return {
        ...acc,
        [type]: deleteOperator,
      }
    },
    {},
  )

  const deleteOperator = useCallback(
    ({ id, type }: { id: string; type: OperatorType }) => {
      getDelete()(id, { layerId })
      function getDelete() {
        return deleteOperatorMap[type]
      }
    },
    [layerId, deleteOperatorMap],
  )

  return deleteOperator
}
