import * as React from 'react'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import {
  LayerRouteComponentProps,
  useRouterProjectWithRouterProps,
  useRouterLayerWithRouterProps,
} from 'router'

export function ProjectBreadcrumbs(props: LayerRouteComponentProps) {
  const { project } = useRouterProjectWithRouterProps(props)
  const { layer } = useRouterLayerWithRouterProps(props)

  return (
    <Breadcrumb style={{ margin: '16px 0' }}>
      <Breadcrumb.Item>
        <Link to="/projects">Projects</Link>
      </Breadcrumb.Item>
      {project && (
        <Breadcrumb.Item key={project.id}>
          <Link to={`/projects/${project.id}`}>{project.name}</Link>
        </Breadcrumb.Item>
      )}
      {layer && <Breadcrumb.Item key={layer.id}>{layer.name}</Breadcrumb.Item>}
    </Breadcrumb>
  )
}
