import React, { useState } from 'react'
import { Icon } from 'antd'
import { ChromePicker, ColorResult } from 'react-color'
import { autoId } from 'utils'
import ColorStopsHolder from './ColorStopsHolder'
import { Palette, TPalette } from './Palette'
import tinycolor from 'tinycolor2'

const HALF_STOP_WIDTH = 5

type Props = {
  width: number
  height: number
  palette: TPalette
  onPaletteChange(palette: TPalette): void
}

export function GradientBuilder(props: Props) {
  const [activeId, setActiveId] = useState('0')
  const [pointX, setPointX] = useState(0)

  const min = -HALF_STOP_WIDTH
  const max = props.width + 1 - HALF_STOP_WIDTH

  return (
    <div
      style={{
        touchAction: 'none',
        userSelect: 'none',
      }}
    >
      <Palette
        width={props.width}
        height={props.height}
        palette={props.palette}
      />
      <ColorStopsHolder
        width={props.width}
        stops={mapStateToStops()}
        limits={limits()}
        onPosChange={handlePosChange}
        onAddColor={handleAddColor}
        onActivate={setActiveId}
        onDeleteColor={handleDeleteColor}
      />
      <div style={{ marginTop: 10 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 8,
            cursor: 'pointer',
          }}
          onClick={handleDeleteActiveId}
        >
          <Icon type="delete" />
          <div style={{ marginLeft: 4 }}>Delete Key</div>
        </div>
        <ChromePicker color={activeColor()} onChange={handleSelectColor} />
      </div>
    </div>
  )

  function handleDeleteActiveId() {
    return handleDeleteColor(activeId)
  }

  function limits() {
    return {
      min,
      max,
      drop: 100, // FIXME is this good?
    }
  }

  function activeColor() {
    const stop = props.palette.find(_ => _.id === activeId)
    return stop ? stop.color : 'black'
  }

  function mapStateToStops() {
    return props.palette.map(stop => ({
      ...stop,
      pos: (props.width + 1) * stop.pos - HALF_STOP_WIDTH,
      isActive: stop.id === activeId,
      pointX,
    }))
  }

  function handleDeleteColor(id: string) {
    if (props.palette.length < 3) return
    const palette = props.palette.filter(_ => _.id !== id)
    const activeId = palette.reduce(
      (a, x) => (x.pos < a.pos ? x : a),
      palette[0],
    ).id
    setActiveId(activeId)
    props.onPaletteChange(palette)
  }

  function handlePosChange({ id, pos }: { id: string; pos: number }) {
    const palette = props.palette.map(stop => {
      if (id === stop.id) {
        return { ...stop, pos: (pos + HALF_STOP_WIDTH) / (props.width + 1) }
      }
      return { ...stop }
    })
    props.onPaletteChange(palette)
  }

  function handleAddColor({ pos, pointX }: { pos: number; pointX: number }) {
    const color = activeColor()
    const entry = { id: autoId(), pos: pos / (props.width + 1), color }
    const palette = [...props.palette, entry]
    setPointX(pointX)
    props.onPaletteChange(palette)
  }

  function handleSelectColor(result: ColorResult) {
    const color = tinycolor(result.rgb).toRgbString()
    const palette = props.palette.map(stop => {
      if (activeId === stop.id) {
        return {
          ...stop,
          color,
        }
      }
      return { ...stop }
    })
    props.onPaletteChange(palette)
  }
}
