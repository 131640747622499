import {
  ListViewersDocument,
  ListViewersQuery,
  ProjectFragment,
  ProjectFragmentDoc,
  useCreateViewerMutation,
  useDeleteViewerMutation,
  useUpdateViewerMutation,
  ViewerFragment,
  ViewerFragmentDoc,
  ViewerUpdateInput,
} from '@lumn-color/hooks'
import produce from 'immer'
import { pickBy } from 'lodash'
import { useCallback } from 'react'
import { useApolloClient } from 'react-apollo-hooks'
import { autoId, MutationHookVariablesData } from 'utils'
import { oc } from 'ts-optchain'

export function useCreateViewer() {
  const create = useCreateViewerMutation()
  return useCallback(
    (data: MutationHookVariablesData<typeof useCreateViewerMutation>) => {
      const variables = {
        data: {
          id: autoId(),
          ...data,
        },
      }
      return create({
        variables,
        optimisticResponse: {
          __typename: 'Mutation',
          createViewer: {
            __typename: 'Viewer',
            ...variables.data,
            project: null,
          },
        },
        update(proxy, result) {
          const queryOptions = {
            query: ListViewersDocument,
          }
          const query = proxy.readQuery<ListViewersQuery>(queryOptions)!
          proxy.writeQuery({
            ...queryOptions,
            data: produce(query, draft => {
              draft.viewers.push(result.data!.createViewer)
            }),
          })
        },
      })
    },
    [create],
  )
}

export function useDeleteViewer() {
  const client = useApolloClient()
  const deleteViewer = useDeleteViewerMutation()
  const onDelete = useCallback(
    (id: string) => {
      return deleteViewer({
        variables: {
          where: {
            id,
          },
        },
        optimisticResponse() {
          return {
            __typename: 'Mutation',
            deleteViewer: client.cache.readFragment<ViewerFragment>({
              id: `Viewer:${id}`,
              fragment: ViewerFragmentDoc,
              fragmentName: 'Viewer',
            })!,
          }
        },
        update(proxy, mutationResult) {
          proxy.writeQuery({
            query: ListViewersDocument,
            data: produce(
              proxy.readQuery<ListViewersQuery>({
                query: ListViewersDocument,
              })!,
              draft => {
                draft.viewers.splice(
                  draft.viewers.findIndex(_ => _.id === id),
                  1,
                )
              },
            ),
          })
          proxy.delete(`Viewer:${id}`)
        },
      })
    },
    [deleteViewer, client.cache],
  )

  return onDelete
}

// Implements optimisticResponse, writing project property from cache
export function useUpdateViewer() {
  const update = useUpdateViewerMutation()
  const client = useApolloClient()
  return useCallback(
    (id: string, data: ViewerUpdateInput) => {
      update({
        variables: {
          where: { id },
          data,
        },
        optimisticResponse() {
          const projectId = oc(data).project.connect.id()
          const project = projectId
            ? client.cache.readFragment<ProjectFragment>({
                id: `Project:${projectId}`,
                fragment: ProjectFragmentDoc,
                fragmentName: 'Project',
              })
            : null
          const model = client.cache.readFragment<ViewerFragment>({
            id: `Viewer:${id}`,
            fragment: ViewerFragmentDoc,
            fragmentName: 'Viewer',
          })!
          return {
            __typename: 'Mutation',
            updateViewer: {
              __typename: 'Viewer',
              ...model,
              ...pickBy(data),
              project,
            },
          }
        },
      })
    },
    [update, client.cache],
  )
}
