import uuid from 'uuid/v4'
import { OperatorType } from 'constants/operators'
import { MutationHookOptions, MutationFn } from 'react-apollo-hooks'

export function autoId() {
  return uuid()
    .replace(/-/g, '')
    .substr(0, 24)
}

export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>

export type Maybe<T> = T | void

export type Nullable<T> = { [P in keyof T]: T[P] | null }

export type Dictionary<T> = { [key: string]: T }

export type MutationHookVariablesData<T> = T extends (
  baseOptions?: MutationHookOptions<any, infer V, object> | undefined,
) => MutationFn<any, infer V>
  ? (V extends { data: infer D } ? Omit<D, 'id'> : never)
  : never

export function getOperator(id: string) {
  const [operatorType, operatorId] = id.split(':')
  return {
    id: operatorId,
    type: operatorType as OperatorType,
  }
}
