import { Viewer } from '@lumn-color/hooks'
import useCopyToClipboard from 'react-use/lib/useCopyToClipboard'
import { Card, Icon, Modal, Typography, message } from 'antd'
import { useDeleteViewer } from 'api'
import React, { useCallback } from 'react'
// import { Link } from 'react-router-dom'
import { ViewerView } from './ViewerView'
import { Label } from 'components/Field/Label'

const { Text } = Typography

const { confirm } = Modal

type Props = {
  viewer: Viewer
}

export function ViewerCard({ viewer }: Props) {
  const deleteViewer = useDeleteViewer()
  const [, copyToClipboard] = useCopyToClipboard()

  const onDeleteViewer = useCallback(
    () => {
      confirm({
        title: `Are you sure you want to delete viewer ${viewer.name}?`,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          deleteViewer(viewer.id)
        },
      })
    },
    [deleteViewer, viewer.id, viewer.name],
  )

  return (
    <Card actions={actions()} title={viewer.name}>
      <div style={{ display: 'block' }}>
        <Label title="ID" />
      </div>
      <div
        onClick={copyViewerIdToClipboard}
        style={{
          cursor: 'pointer',
          paddingBottom: '8px',
          display: 'inline-block',
        }}
      >
        <Text>{viewer.id}</Text>
        &nbsp;
        <Icon type="copy" />
      </div>
      <ViewerView viewer={viewer} />
    </Card>
  )

  function copyViewerIdToClipboard() {
    copyToClipboard(viewer.id)
    message.success('Copied ID to clipboard')
  }

  function actions() {
    return [
      <Icon type="delete" onClick={onDeleteViewer} />,
      // <Link to={`/viewers/${viewer.id}`}>
      // <Icon type="eye" />
      // </Link>,
    ]
  }
}
