import * as React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ProjectList } from 'components/ProjectList'
import { ProjectMasterDetail } from 'components/ProjectMasterDetail'
import { ViewerList } from 'components/ViewerList'

export function AppContent() {
  return (
    <Switch>
      <Route path="/" exact component={() => <Redirect to="/viewers" />} />
      <Route path="/viewers" exact component={ViewerList} />
      <Route path="/projects" exact component={ProjectList} />
      <Route
        path="/projects/:projectId"
        exact
        component={ProjectMasterDetail}
      />
      <Route
        path="/projects/:projectId/layers/:layerId"
        exact
        component={ProjectMasterDetail}
      />
    </Switch>
  )
}
