import { RouteComponentProps } from 'react-router'
import useRouter from 'use-react-router'
import { useGetFullLayerQuery } from '@lumn-color/hooks'
import { oc } from 'ts-optchain'

export type LayerRouteProps = {
  projectId?: string
  layerId?: string
}

export type LayerRouteComponentProps = RouteComponentProps<LayerRouteProps>

export function useRouterLayer() {
  const routerProps = useRouter<LayerRouteProps>()
  return useRouterLayerWithRouterProps(routerProps)
}

export function useRouterLayerWithRouterProps(
  routeProps: LayerRouteComponentProps,
) {
  const layerId = routerLayerId(routeProps)
  const { data, loading } = useGetFullLayerQuery({
    skip: !layerId,
    variables: {
      where: { id: layerId },
    },
    fetchPolicy: 'cache-only',
  })
  const layer = data && data.layer
  return { layer, loading }
}

export function useIsRouterLayer(layerId: string) {
  const routerProps = useRouter<LayerRouteProps>()
  return layerId === routerLayerId(routerProps)
}

export function useRouterLayerId() {
  const routerProps = useRouter<LayerRouteProps>()
  return routerLayerId(routerProps)
}

function routerLayerId({ location, match }: LayerRouteComponentProps) {
  if (
    !location.pathname.startsWith('/projects/') ||
    !location.pathname.includes('/layers/')
  ) {
    return null
  }
  return oc(match).params.layerId()
}
