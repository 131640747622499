// @ts-ignore
import isArray from 'lodash/isArray'
// @ts-ignore
import isPlainObject from 'lodash/isPlainObject'
import { InMemoryCache } from 'apollo-cache-inmemory'

/**
 * Recursively delete all properties matching with the given predicate function in the given value
 * @param {Object} value
 * @param {Function} predicate
 * @return the number of deleted properties or indexes
 */
function deepDeleteAll(value: any, predicate: any) {
  let count = 0
  if (isArray(value)) {
    value.forEach((item, index) => {
      if (predicate(item)) {
        value.splice(index, 1)
        count++
      } else {
        count += deepDeleteAll(item, predicate)
      }
    })
  } else if (isPlainObject(value)) {
    Object.keys(value).forEach(key => {
      if (predicate(value[key])) {
        delete value[key]
        count++
      } else {
        count += deepDeleteAll(value[key], predicate)
      }
    })
  }
  return count
}

// TODO does not work with list queries
/**
 * Improve InMemoryCache prototype with a function deleting an entry and all its
 * references in cache.
 */
// @ts-ignore
InMemoryCache.prototype.delete = function(id: string) {
  // get entry id
  // delete all entry references from cache
  // @ts-ignore
  deepDeleteAll(
    // @ts-ignore
    this.data.data,
    (ref: any) => ref && (ref.type === 'id' && ref.id === id),
  )

  // delete entry from cache (and trigger UI refresh)
  // @ts-ignore
  this.data.delete(id)
}
