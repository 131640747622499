import {
  RemapFragment,
  RemapFragmentDoc,
  useGetRemapQuery,
  useUpdateRemapMutation,
} from '@lumn-color/hooks'
import { Field, FieldProps } from 'components/Field'
import { createUpdateFieldHook } from 'hooks/useUpdateField'
import * as React from 'react'
import { Omit } from 'utils'
import { sourceOptions, extendOptions } from 'constants/fields'

const fields: Omit<EntityFieldProps, 'id'>[] = [
  {
    label: 'Name',
    fields: ['name'],
  },
  {
    label: 'Horizontal Source',
    type: 'enum',
    options: sourceOptions,
    fields: ['horizontalSource'],
  },
  {
    label: 'Vertical Source',
    type: 'enum',
    options: sourceOptions,
    fields: ['verticalSource'],
  },
  {
    label: 'Flip Horizontal Direction',
    type: 'boolean',
    fields: ['flipHorizontalDirection'],
  },
  {
    label: 'Flip Vertical Direction',
    type: 'boolean',
    fields: ['flipVerticalDirection'],
  },
  {
    label: 'Extend',
    type: 'enum',
    options: extendOptions,
    fields: ['extend'],
  },
  {
    label: 'Source',
    type: 'input',
    fields: ['input1'],
  },
  {
    label: 'UV',
    type: 'input',
    fields: ['input2'],
  },
]

const useUpdateField = createUpdateFieldHook<RemapFragment>({
  __typename: 'Remap',
  fragmentDoc: RemapFragmentDoc,
  queryName: 'remap',
  mutationName: 'updateRemap',
  useGetQuery: useGetRemapQuery,
  useUpdateMutation: useUpdateRemapMutation,
})

type EntityFieldProps = Omit<FieldProps<RemapFragment>, 'useUpdateField'>

function EntityField(props: EntityFieldProps) {
  return <Field<RemapFragment> {...props} useUpdateField={useUpdateField} />
}

export const Remap = React.memo(function EntityView({ id }: { id: string }) {
  return <>{entityFields()}</>

  function entityFields() {
    return fields.map((field, index) => (
      <EntityField key={index} id={id} {...field} />
    ))
  }
})
