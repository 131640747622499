import React from 'react'
import { Button, List, Row } from 'antd'
import useWindowSize from 'react-use/lib/useWindowSize'

type Props<T extends unknown> = {
  onCreate(): void
  loading: boolean
  dataSource: T[]
  renderItem(item: T): React.ReactNode
}

const MOBILE_WIDTH = 500

export function CardList<T extends unknown>(props: Props<T>) {
  const size = useWindowSize()

  const column = size.width > MOBILE_WIDTH ? 2 : 1

  return (
    <>
      <Row type="flex" justify="end">
        <Button
          type="primary"
          shape="circle"
          icon="plus"
          onClick={props.onCreate}
        />
      </Row>
      <List
        loading={props.loading}
        style={{ padding: 24, paddingBottom: 100 }}
        grid={{ gutter: 24, column }}
        dataSource={props.dataSource}
        renderItem={item => <List.Item>{props.renderItem(item)}</List.Item>}
      />
    </>
  )
}
