import {
  CompositeFragment,
  CompositeFragmentDoc,
  useGetCompositeQuery,
  useUpdateCompositeMutation,
  TransformOrder,
  Operation,
  JustifyHorizontal,
  JustifyVertical,
  Fit,
} from '@lumn-color/hooks'
import { Field, FieldProps } from 'components/Field'
import { createUpdateFieldHook } from 'hooks/useUpdateField'
import * as React from 'react'
import { Omit } from 'utils'
import { extendOptions } from 'constants/fields'

function optionify<T>(t: T) {
  return Object.values(t).map(_ => ({
    label: _.replace(/_/g, ' '),
    value: _,
  }))
}

const fields: Omit<EntityFieldProps, 'id'>[] = [
  {
    label: 'Name',
    fields: ['name'],
  },
  {
    label: 'Operation',
    type: 'enum',
    options: optionify(Operation),
    fields: ['operation'],
  },
  {
    label: 'Translate',
    type: 'number',
    expressions: true,
    fields: ['translateX', 'translateY'],
  },
  {
    label: 'Rotate',
    type: 'number',
    expressions: true,
    fields: ['rotate'],
  },
  {
    label: 'Scale',
    type: 'number',
    expressions: true,
    fields: ['scaleX', 'scaleY'],
  },
  {
    label: 'Fit',
    type: 'enum',
    options: optionify(Fit),
    fields: ['fit'],
  },
  {
    label: 'Justify Horizontal',
    type: 'enum',
    options: optionify(JustifyHorizontal),
    fields: ['justifyHorizontal'],
  },
  {
    label: 'Justify Vertical',
    type: 'enum',
    options: optionify(JustifyVertical),
    fields: ['justifyVertical'],
  },
  {
    label: 'Transform Order',
    type: 'enum',
    options: optionify(TransformOrder),
    fields: ['transformOrder'],
  },
  {
    label: 'Extend',
    type: 'enum',
    options: extendOptions,
    fields: ['extend'],
  },
  {
    label: 'Input 1',
    type: 'input',
    fields: ['input1'],
  },
  {
    label: 'Input 2',
    type: 'input',
    fields: ['input2'],
  },
]

const useUpdateField = createUpdateFieldHook<CompositeFragment>({
  __typename: 'Composite',
  fragmentDoc: CompositeFragmentDoc,
  queryName: 'composite',
  mutationName: 'updateComposite',
  useGetQuery: useGetCompositeQuery,
  useUpdateMutation: useUpdateCompositeMutation,
})

type EntityFieldProps = Omit<FieldProps<CompositeFragment>, 'useUpdateField'>

function EntityField(props: EntityFieldProps) {
  return <Field<CompositeFragment> {...props} useUpdateField={useUpdateField} />
}

export const Composite = React.memo(function({ id }: { id: string }) {
  return <>{entityFields()}</>

  function entityFields() {
    return fields.map((field, index) => (
      <EntityField key={index} id={id} {...field} />
    ))
  }
})
