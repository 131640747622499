import { RouteComponentProps } from 'react-router'
import useRouter from 'use-react-router'
import { useGetViewerQuery } from '@lumn-color/hooks'
import { oc } from 'ts-optchain'

export type ViewerRouteProps = {
  viewerId?: string
}

export type ViewerRouteComponentProps = RouteComponentProps<ViewerRouteProps>

export function useRouterViewer() {
  const routerProps = useRouter<ViewerRouteProps>()
  return useRouterViewerWithRouterProps(routerProps)
}

export function useRouterViewerWithRouterProps(
  routeProps: ViewerRouteComponentProps,
) {
  const viewerId = routerViewerId(routeProps)
  const { data, loading } = useGetViewerQuery({
    skip: !viewerId,
    variables: {
      where: { id: viewerId },
    },
    fetchPolicy: 'cache-only',
  })
  const viewer = data && data.viewer
  return { viewer, loading }
}

export function useIsRouterViewer(viewerId: string) {
  const routerProps = useRouter<ViewerRouteProps>()
  return viewerId === routerViewerId(routerProps)
}

export function useRouterViewerId() {
  const routerProps = useRouter<ViewerRouteProps>()
  return routerViewerId(routerProps)
}

function routerViewerId({ location, match }: ViewerRouteComponentProps) {
  if (!location.pathname.startsWith('/viewers/')) return null
  return oc(match).params.viewerId()
}
