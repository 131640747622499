/*
  File Description:
  This file implements cache updates and optimistic responses for operators.
  All operators write back to their FullLayerFragment
*/

import { FullLayerFragment, FullLayerFragmentDoc } from '@lumn-color/hooks'
import { omit } from 'lodash'
import { useCallback } from 'react'
import produce from 'immer'
import {
  useApolloClient,
  MutationFn,
  MutationHookOptions,
} from 'react-apollo-hooks'
import { autoId, Omit } from 'utils'

export function createUseCreateOperator<TData, TVariables>({
  mutationHook,
  __typename,
  mutationName,
  layersPath,
  requiredProperties,
}: {
  mutationHook: MutationHook<TData, TVariables>
  __typename: string
  mutationName: string
  layersPath: LayersPath
  requiredProperties?: any
}) {
  return function useCreate() {
    const create = mutationHook()
    return useCallback(
      function(data: OperatorMutationData<TVariables>) {
        const variables = {
          data: {
            id: autoId(),
            ...data,
          },
        }
        return create({
          variables,
          optimisticResponse() {
            return {
              [mutationName]: {
                __typename,
                ...omit(variables.data, 'layer'),
                ...requiredProperties,
              },
            }
          },
          update(proxy, result) {
            const options = {
              fragment: FullLayerFragmentDoc,
              fragmentName: 'FullLayer',
              id: `Layer:${variables.data.layer.connect.id}`,
            }
            const fragment = proxy.readFragment<FullLayerFragment>(options)!
            const data = produce(fragment, draft => {
              if (!draft[layersPath]) draft[layersPath] = []
              draft[layersPath]!.push(result.data![mutationName])
            })
            proxy.writeFragment({
              ...options,
              data,
            })
          },
        })
      },
      [create],
    )
  }
}

export function createUseDeleteOperator<TData, TVariables>({
  mutationHook,
  __typename,
  mutationName,
  fragmentDoc,
  fragmentName,
  layersPath,
}: {
  mutationHook: MutationHook<TData, TVariables>
  __typename: string
  mutationName: string
  fragmentDoc: any
  fragmentName: string
  layersPath: LayersPath
}) {
  return function useDelete() {
    const client = useApolloClient()
    const deleteEntity = mutationHook()
    const onDelete = useCallback(
      (id: string, { layerId }: { layerId: string }) =>
        deleteEntity({
          variables: {
            where: {
              id: id,
            },
          },
          optimisticResponse() {
            return {
              [mutationName]: client.cache.readFragment<any>({
                id: `${__typename}:${id}`,
                fragment: fragmentDoc,
                fragmentName,
              })!,
            }
          },
          update(proxy) {
            const options = {
              fragment: FullLayerFragmentDoc,
              fragmentName: 'FullLayer',
              id: `Layer:${layerId}`,
            }
            const fragment = proxy.readFragment<FullLayerFragment>(options)!
            const data = produce(fragment, draft => {
              draft[layersPath]!.splice(
                draft[layersPath]!.findIndex((_: any) => _.id === id),
                1,
              )
            })
            proxy.writeFragment({
              ...options,
              data,
            })
          },
        }),
      [deleteEntity, client.cache],
    )
    return onDelete
  }
}

type MutationHook<TData, TVariables> = (
  baseOptions?: MutationHookOptions<TData, TVariables, object> | undefined,
) => MutationFn<any, any>

type MutationData<TVariables> = TVariables extends { data: infer D }
  ? Omit<D, 'id'>
  : never

type OperatorMutationData<TVariables> = MutationData<TVariables> & {
  layer: { connect: { id: string } }
}

type LayersPath = keyof PickListKeys<FullLayerFragment>

type PickListKeys<T> = Pick<T, Exclude<keyof T, ListKeys<T>>>

type ListKeys<T> = {
  [P in keyof T]: T[P] extends any[] | null ? never : P
}[keyof T]
