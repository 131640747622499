import { useGetFullLayerQuery } from '@lumn-color/hooks'
import { operatorKeys } from 'constants/operators'
import { getOperator } from 'utils'
import { oc } from 'ts-optchain'

export function useLayerOperators({ layerId }: { layerId?: string | null }) {
  const { data } = useGetFullLayerQuery({
    skip: !layerId,
    variables: { where: { id: layerId } },
  })
  const layer = data && data.layer

  const operators = operatorKeys.map(operatorKey => {
    return (layer && layer[operatorKey]) || []
  })

  // TODO a `flatMap` that resolves correct types
  return operators.reduce(
    // @ts-ignore
    (acc, list) => [...acc, ...list],
    [],
  ) as (typeof operators[0][0])[]
}

export function useLayerSelectedOperator({ layerId }: { layerId: string }) {
  const { data } = useGetFullLayerQuery({
    variables: { where: { id: layerId } },
  })
  const id = oc(data).layer.input()
  if (!id) return null
  if (id) return getOperator(id)
}
