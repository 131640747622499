import { useRouterProject } from 'router'

export function useDownloadProject() {
  const { project } = useRouterProject()
  const filename = project ? `${project.name}.lumn` : 'data.lumn'

  return function() {
    return download(jsonUrl(project!), filename)
  }
}

function jsonUrl(data: object) {
  const MIME_TYPE = 'application/json'
  const blob = new Blob([JSON.stringify(data)], { type: MIME_TYPE })
  return window.URL.createObjectURL(blob)
}

function download(url: string, name: string) {
  const a = document.createElement('a')
  a.hidden = true
  a.href = url
  a.target = '_blank'
  a.download = name
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
