import { omit, isEqual } from 'lodash'
import { useState, useEffect, useMemo } from 'react'
import {
  useUpdateRampMutation,
  useGetRampQuery,
  RampKeyFragment,
} from '@lumn-color/hooks'
import useDebounce from 'react-use/lib/useDebounce'
import { rampKeysDifference } from './rampKeysDifference'

const wait = 500

export function useUpdateRampKeys(params: { rampId: string }) {
  const result = useGetRampQuery({
    variables: {
      where: {
        id: params.rampId,
      },
    },
    fetchPolicy: 'cache-only',
  })
  const updateRamp = useUpdateRampMutation()

  const cachedKeys: RampKeyFragment[] =
    result.data && result.data.ramp && result.data.ramp.keys
      ? result.data.ramp.keys
      : []

  const [keys, setKeys] = useState(cachedKeys)

  useEffect(() => setKeys(cachedKeys), [cachedKeys])

  const syncing = useMemo(() => !isEqual(keys, cachedKeys), [keys, cachedKeys])

  useDebounce(
    () => {
      const { created, updated, deleted } = rampKeysDifference({
        prev: cachedKeys,
        curr: keys,
      })

      if (
        created.length === 0 &&
        updated.length === 0 &&
        deleted.length === 0
      ) {
        return
      }

      updateRamp({
        variables: {
          where: {
            id: params.rampId,
          },
          data: {
            keys: {
              create: created,
              update: updated.map(_ => ({
                where: {
                  id: _.id,
                },
                data: {
                  ...omit(_, 'id'),
                },
              })),
              deleteMany: [
                {
                  id_in: deleted.map(_ => _.id),
                },
              ],
            },
          },
        },
      })
    },
    wait,
    [keys, cachedKeys],
  )

  return {
    keys,
    setKeys,
    syncing,
  }
}
