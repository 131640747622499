import * as React from 'react'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import {
  ViewerRouteComponentProps,
  useRouterViewerWithRouterProps,
} from 'router'

export function ViewerBreadcrumbs(props: ViewerRouteComponentProps) {
  const { viewer, loading } = useRouterViewerWithRouterProps(props)

  return (
    <Breadcrumb style={{ margin: '16px 0' }}>
      <Breadcrumb.Item>
        <Link to="/viewers">Viewers</Link>
      </Breadcrumb.Item>
      {!loading && viewer && (
        <Breadcrumb.Item key={viewer.id}>{viewer.name}</Breadcrumb.Item>
      )}
    </Breadcrumb>
  )
}
