import {
  DisplaceFragment,
  DisplaceFragmentDoc,
  useGetDisplaceQuery,
  useUpdateDisplaceMutation,
} from '@lumn-color/hooks'
import { Field, FieldProps } from 'components/Field'
import { createUpdateFieldHook } from 'hooks/useUpdateField'
import * as React from 'react'
import { Omit } from 'utils'
import { sourceOptions, extendOptions } from 'constants/fields'

const fields: Omit<EntityFieldProps, 'id'>[] = [
  {
    label: 'Name',
    fields: ['name'],
  },
  {
    label: 'Horizontal Source',
    type: 'enum',
    options: sourceOptions,
    fields: ['horizontalSource'],
  },
  {
    label: 'Vertical Source',
    type: 'enum',
    options: sourceOptions,
    fields: ['verticalSource'],
  },
  {
    label: 'Source Midpoint',
    type: 'number',
    fields: ['sourceMidpointX', 'sourceMidpointY'],
  },
  {
    label: 'Displace Weight',
    type: 'number',
    fields: ['displaceWeightX', 'displaceWeightY'],
  },
  {
    label: 'UV Weight',
    type: 'number',
    fields: ['uvWeight'],
  },
  {
    label: 'Offset',
    type: 'number',
    fields: ['offsetX', 'offsetY'],
  },
  {
    label: 'Offset Weight',
    type: 'number',
    fields: ['offsetWeight'],
  },
  {
    label: 'Extend',
    type: 'enum',
    options: extendOptions,
    fields: ['extend'],
  },
  {
    label: 'Source',
    type: 'input',
    fields: ['input1'],
  },
  {
    label: 'UV',
    type: 'input',
    fields: ['input2'],
  },
]

const useUpdateField = createUpdateFieldHook<DisplaceFragment>({
  __typename: 'Displace',
  fragmentDoc: DisplaceFragmentDoc,
  queryName: 'displace',
  mutationName: 'updateDisplace',
  useGetQuery: useGetDisplaceQuery,
  useUpdateMutation: useUpdateDisplaceMutation,
})

type EntityFieldProps = Omit<FieldProps<DisplaceFragment>, 'useUpdateField'>

function EntityField(props: EntityFieldProps) {
  return <Field<DisplaceFragment> {...props} useUpdateField={useUpdateField} />
}

export const Displace = React.memo(function EntityView({ id }: { id: string }) {
  return <>{entityFields()}</>

  function entityFields() {
    return fields.map((field, index) => (
      <EntityField key={index} id={id} {...field} />
    ))
  }
})
