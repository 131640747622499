import * as React from 'react'
import { Select } from 'antd'
import { Label } from './Label'
import { Field as IField, Model } from 'types/model'
import { useUpdateFieldSimple } from 'hooks/useUpdateFieldSimple'

const { Option } = Select

type FieldUpdater = ReturnType<typeof useUpdateFieldSimple>

export type SelectOption = {
  label: string
  value: string
}

type Props<M extends Model, F extends IField<M>> = {
  label: string
  options: SelectOption[]
  fieldUpdaters: FieldUpdater[]
}

export const SelectField = React.memo(function<
  M extends Model,
  F extends IField<M>
>(props: Props<M, F>) {
  return (
    <div style={{ marginBottom: 8 }}>
      <Label title={props.label} syncing={syncing()} />
      <div style={{ display: 'flex' }}>{renderInputs()}</div>
    </div>
  )

  function renderInputs() {
    return props.fieldUpdaters.map((fieldUpdater, index) => {
      return (
        <Select<string>
          size="small"
          key={index}
          style={{ width: 200 }}
          defaultValue={fieldUpdater.value}
          onChange={value => fieldUpdater.setValue(value)}
        >
          {props.options.map(_ => (
            <Option key={_.value} value={_.value}>
              {_.label}
            </Option>
          ))}
        </Select>
      )
    })
  }

  function syncing() {
    return props.fieldUpdaters.some(_ => _.syncing)
  }
})
