import {
  FullLayerFragment,
  FullProjectFragment,
  FullProjectFragmentDoc,
  useGetFullLayerQuery,
  useCreateFullLayerMutation,
} from '@lumn-color/hooks'
import { omit } from 'lodash'
import { useCallback } from 'react'
import { Dictionary, autoId, MutationHookVariablesData } from 'utils'
import { operatorKeys } from 'constants/operators'
import { oc } from 'ts-optchain'
import produce from 'immer'

type EmptyOperators = { [key in typeof operatorKeys[number]]: [] }

const emptyOperators = operatorKeys.reduce(
  (acc, opKey) => ({
    ...acc,
    [opKey]: [],
  }),
  {},
) as EmptyOperators

function useCreateFullLayer() {
  const create = useCreateFullLayerMutation()
  return useCallback(
    (data: MutationHookVariablesData<typeof useCreateFullLayerMutation>) => {
      const variables = {
        data: {
          id: autoId(),
          ...data,
        },
      }
      return create({
        variables,
        update(proxy, result) {
          const fragmentOptions = {
            fragment: FullProjectFragmentDoc,
            fragmentName: 'FullProject',
            id: `Project:${variables.data.project.connect!.id!}`,
          }
          const fragment = proxy.readFragment<FullProjectFragment>(
            fragmentOptions,
          )!
          proxy.writeFragment({
            ...fragmentOptions,
            data: produce(fragment, draft => {
              if (!draft.layers) draft.layers = []
              draft.layers.push({
                ...emptyOperators,
                ...result.data!.createLayer,
              })
            }),
          })
        },
      })
    },
    [create],
  )
}

export function useCopyLayer({
  layerId,
  projectId,
}: {
  projectId: string
  layerId: string
}) {
  const { data } = useGetFullLayerQuery({
    variables: { where: { id: layerId } },
    fetchPolicy: 'cache-only',
  })

  const layer = oc(data).layer()
  const createFullLayer = useCreateFullLayer()

  const onCopyLayer = useCallback(() => {
    if (!layer) return

    const idLookup: Dictionary<string> = {}

    populateIdLookup(layer, idLookup)

    createFullLayer({
      ...buildFullLayer(layer, idLookup),
      name: `${layer.name} Copy`,
      project: {
        connect: {
          id: projectId,
        },
      },
    })
  }, [projectId, createFullLayer, layer])

  return onCopyLayer
}

export function buildFullLayer(
  layer: FullLayerFragment,
  idLookup: Dictionary<string>,
) {
  return {
    ...omit(layer, ['id', '__typename', ...operatorKeys]),
    ...createInput(layer, 'input'),
    ...createOperators(),
    ...createRampOperator(),
  }

  function createOperators() {
    const ops = operatorKeys.filter(_ => _ !== 'ramps') // exclude ramps
    return ops.reduce(
      (acc, op) => ({
        ...acc,
        ...createOperator(op),
      }),
      {},
    )
  }

  function createOperator(operatorKey: typeof operatorKeys[number]) {
    const ops: any[] = (layer && layer[operatorKey]) || []
    return {
      [operatorKey]: {
        create: ops.map(op => ({
          ...omit(op, '__typename'),
          id: idLookup[op.id],
          ...createInput(op, 'input1'),
          ...createInput(op, 'input2'),
        })),
      },
    }
  }

  function createRampOperator() {
    return {
      ramps: {
        create: oc(layer)
          .ramps([])
          .map(_ => ({
            ...omit(_, '__typename'),
            id: idLookup[_.id],
            keys: {
              create: (_.keys || []).map(__ => ({
                ...omit(__, '__typename'),
                id: idLookup[__.id],
              })),
            },
          })),
      },
    }
  }

  function createInput(obj: any, key: string) {
    if (!obj[key]) return null
    const typename = obj[key].split(':')[0]
    const id = idLookup[obj[key].split(':')[1]]
    return {
      [key]: `${typename}:${id}`,
    }
  }
}

export function populateIdLookup(o: any, idLookup: Dictionary<string>) {
  if (typeof o !== 'object') return
  if (Array.isArray(o)) {
    o.forEach(x => {
      populateIdLookup(x, idLookup)
    })
    return
  }
  for (const k in o) {
    if (k === 'id') idLookup[o[k]] = autoId()
    populateIdLookup(o[k], idLookup)
  }
}
