import {
  TransformFragment,
  TransformFragmentDoc,
  useGetTransformQuery,
  useUpdateTransformMutation,
  TransformOrder,
} from '@lumn-color/hooks'
import { Field, FieldProps } from 'components/Field'
import { createUpdateFieldHook } from 'hooks/useUpdateField'
import * as React from 'react'
import { Omit } from 'utils'
import { extendOptions } from 'constants/fields'

const transformOrderOptions = Object.values(TransformOrder).map(_ => ({
  label: _.replace(/_/g, ' '),
  value: _,
}))

const fields: Omit<EntityFieldProps, 'id'>[] = [
  {
    label: 'Name',
    fields: ['name'],
  },
  {
    label: 'Resolution',
    type: 'number',
    fields: ['resolutionW', 'resolutionH'],
  },
  {
    label: 'Translate',
    type: 'number',
    expressions: true,
    fields: ['translateX', 'translateY'],
  },
  {
    label: 'Rotate',
    type: 'number',
    expressions: true,
    fields: ['rotate'],
  },
  {
    label: 'Scale',
    type: 'number',
    expressions: true,
    fields: ['scaleX', 'scaleY'],
  },
  {
    label: 'Transform Order',
    type: 'enum',
    options: transformOrderOptions,
    fields: ['transformOrder'],
  },
  {
    label: 'Extend',
    type: 'enum',
    options: extendOptions,
    fields: ['extend'],
  },
  {
    label: 'Input',
    type: 'input',
    fields: ['input1'],
  },
]

const useUpdateField = createUpdateFieldHook<TransformFragment>({
  __typename: 'Transform',
  fragmentDoc: TransformFragmentDoc,
  queryName: 'transform',
  mutationName: 'updateTransform',
  useGetQuery: useGetTransformQuery,
  useUpdateMutation: useUpdateTransformMutation,
})

type EntityFieldProps = Omit<FieldProps<TransformFragment>, 'useUpdateField'>

function EntityField(props: EntityFieldProps) {
  return <Field<TransformFragment> {...props} useUpdateField={useUpdateField} />
}

export const Transform = React.memo(function EntityView({
  id,
}: {
  id: string
}) {
  return <>{entityFields()}</>

  function entityFields() {
    return fields.map((field, index) => (
      <EntityField key={index} id={id} {...field} />
    ))
  }
})
