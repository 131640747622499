import {
  useGetConstantQuery,
  useGetDisplaceQuery,
  useGetNoiseQuery,
  useGetRampQuery,
  useGetRemapQuery,
  useGetTransformQuery,
  useGetCompositeQuery,
} from '@lumn-color/hooks'
import {
  useCreateConstant,
  useCreateDisplace,
  useCreateNoise,
  useCreateRamp,
  useCreateRemap,
  useCreateTransform,
  useCreateComposite,
} from './useOperator'
import { omit } from 'lodash'
import { useCallback } from 'react'
import { Dictionary } from 'utils'
import { OperatorType, operatoryQueryPath } from 'constants/operators'
import { populateIdLookup } from './useCopyLayer'

export function useCopyOperator({
  layerId,
  operatorId,
  type,
}: {
  layerId: string
  operatorId: string
  type: OperatorType
}) {
  const useGetOperatorQuery = operatorQueryHooks[type]

  const { data } = useGetOperatorQuery({
    variables: { where: { id: operatorId } },
    fetchPolicy: 'cache-only',
  })

  const operator: Operator = (data as any)[operatoryQueryPath[type]]

  const useCreateOperatorMutation = operatorCreateHooks[type]
  const createOperator = useCreateOperatorMutation()

  const onCopy = useCallback(() => {
    if (!operator) throw new Error(`Can't copy operator`)

    const idLookup: Dictionary<string> = {}

    populateIdLookup(operator, idLookup)

    createOperator({
      ...buildOperator(operator, idLookup),
      name: `${operator.name} Copy`,
      layer: {
        connect: {
          id: layerId,
        },
      },
    })
  }, [layerId, createOperator, operator])

  return onCopy
}

export function buildOperator(op: Operator, idLookup: Dictionary<string>) {
  if (op.__typename !== 'Ramp') {
    return {
      ...omit(op, ['id', '__typename']),
      ...createInput(op, 'input1', idLookup),
      ...createInput(op, 'input2', idLookup),
    }
  }
  return {
    ...omit(op, ['id', '__typename']),
    keys: {
      // @ts-ignore
      create: (op.keys || []).map(_ => ({
        ...omit(_, '__typename'),
        id: idLookup[_.id],
      })),
    },
  }
}

function createInput(obj: any, key: string, idLookup: Dictionary<string>) {
  if (!obj[key]) return null
  const typename = obj[key].split(':')[0]
  const id = idLookup[obj[key].split(':')[1]]
  return {
    [key]: `${typename}:${id}`,
  }
}

const operatorQueryHooks = {
  Constant: useGetConstantQuery,
  Displace: useGetDisplaceQuery,
  Noise: useGetNoiseQuery,
  Ramp: useGetRampQuery,
  Remap: useGetRemapQuery,
  Transform: useGetTransformQuery,
  Composite: useGetCompositeQuery,
}

const operatorCreateHooks = {
  Constant: useCreateConstant,
  Displace: useCreateDisplace,
  Noise: useCreateNoise,
  Ramp: useCreateRamp,
  Remap: useCreateRemap,
  Transform: useCreateTransform,
  Composite: useCreateComposite,
}

interface Operator {
  __typename?: string
  id: string
  name?: string | null
}
