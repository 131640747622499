import * as React from 'react'
import { Collapse } from 'antd'
import useRouter from 'use-react-router'
import { useProjectLayers } from 'api'
import { ProjectView } from 'components/ProjectView'
import { LayerView } from 'components/LayerView'
import { useRouterProjectId, useRouterLayerId } from 'router'
import { ProjectSettings } from './ProjectSettings'
import { LayerSettings } from './LayerSettings'

const { Panel } = Collapse

export const PanelsView = React.memo(() => {
  const { history } = useRouter()
  const projectId = useRouterProjectId()
  const layerId = useRouterLayerId()
  const projectLayers = useProjectLayers(projectId)

  return (
    <Collapse
      accordion
      destroyInactivePanel
      bordered={false}
      activeKey={[layerId || 'project']}
      onChange={handleKeyChange}
    >
      {projectPanel()}
      {layerPanels()}
    </Collapse>
  )

  function handleKeyChange(key: string | string[]) {
    if (!key || key === 'project') {
      history.push(`/projects/${projectId}`)
    } else {
      history.push(`/projects/${projectId}/layers/${key}`)
    }
  }

  function projectPanel() {
    if (!projectId) return null

    return (
      <Panel
        header="Project"
        key="project"
        showArrow={false}
        extra={<ProjectSettings projectId={projectId} />}
      >
        <ProjectView projectId={projectId} />
      </Panel>
    )
  }

  function layerPanels() {
    return projectLayers.map(layer => {
      return (
        <Panel
          header={layer.name || 'Layer'}
          key={layer.id}
          showArrow={false}
          extra={<LayerSettings layerId={layer.id} />}
        >
          <LayerView layerId={layer.id} />
        </Panel>
      )
    })
  }
})
