import React from 'react'
// import { CanvasView } from './CanvasView'
import { PanelsView } from './PanelsView'
// import { Typography } from 'antd'

// const { Text } = Typography

// <div
// ref={c => {
// if (c) scrollRef.current = c
// }}
// style={{
// width: 381,
// alignSelf: 'flex-start',
// height: '100%',
// overflowY: 'auto',
// }}
// >
// <ProjectContext.Provider value={state}>
// </ProjectContext.Provider>
// const { scrollRef, state } = useProjectContextProvider()
// <div
// style={{
// flex: 1,
// display: 'flex',
// overflowX: 'hidden',
// justifyContent: 'center',
// alignItems: 'center',
// }}
// >
//
// <CanvasView />
//
// </div>
//
// <div
// style={{
// width: 16,
// }}
// />
//
// <div
// style={{
// width: 381,
// alignSelf: 'flex-start',
// height: '100%',
// overflowY: 'auto',
// }}
// >
//
//
// </div>
//

export function ProjectMasterDetail() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
      }}
    >
      <PanelsView />
    </div>
  )
}

// scroll

// type ProjectContext = {
// scrollTo?: (offset: number) => void
// toggleShowCanvas(): void
// showCanvas: boolean
// }
// const initialState: ProjectContext = {
// showCanvas: true,
// toggleShowCanvas: () => null,
// }
// const ProjectContext = createContext<ProjectContext>(initialState)
// export function useProjectContext() {
// return useContext(ProjectContext)
// }
// function useProjectContextProvider() {
// const scrollRef = useRef<HTMLDivElement>()
// const [state, setState] = useState<ProjectContext>(initialState)
// useEffect(() => {
// setState({
// ...state,
// scrollTo(offset: number) {
// if (!scrollRef.current) return
// scrollRef.current.scrollTo({ top: offset, behavior: 'smooth' })
// },
// toggleShowCanvas() {
// setState({
// ...state,
// showCanvas: !state.showCanvas,
// })
// },
// })
// }, [state])
// return { scrollRef, state }
// }
