import React from 'react'
import { Collapse, Icon } from 'antd'
import {
  useCreateOperator,
  useLayerOperators,
  useLayerSelectedOperator,
} from 'api'
import { DropdownMenu } from 'components/DropdownMenu'
import { Label } from 'components/Field/Label'
import { OperatorType } from 'constants/operators'
import { operatorComponent } from './operatorComponent'
import { OperatorExtra } from './OperatorExtra'

const { Panel } = Collapse

export function OperatorList({ layerId }: { layerId: string }) {
  const createOperator = useCreateOperator({ layerId })
  const operators = useLayerOperators({ layerId })
  const selectedOperator = useLayerSelectedOperator({ layerId })

  return (
    <div style={{ marginBottom: 8 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Label title="Texture" />
        {renderActions()}
      </div>
      <Collapse
        onChange={key => {
          if (typeof key === 'string') scrollToKey(key)
        }}
        destroyInactivePanel
        accordion
        bordered={false}
        style={{
          border: '1px solid #d9d9d9',
          borderBottom: 'none',
        }}
      >
        {renderOperators()}
      </Collapse>
    </div>
  )

  function scrollToKey(key: string) {
    // if (!scrollTo) return
    setTimeout(() => {
      const ele = document.getElementById(`operator-${key}`)
      if (!ele) return
      // scrollTo(ele.offsetTop - 200)
    }, 300)
  }

  function renderActions() {
    return (
      <DropdownMenu
        items={Object.values(OperatorType).map((_: OperatorType) => ({
          title: _,
          onClick: () => createOperator(_),
        }))}
      >
        <Icon style={{ cursor: 'pointer' }} type="plus" />
      </DropdownMenu>
    )
  }

  function renderOperators() {
    return operators.map(operator => {
      return (
        <Panel
          header={operator.name || operator.__typename}
          extra={renderViewOperator(operator)}
          key={operator.id}
          showArrow={false}
          style={{ marginTop: 8 }}
        >
          {renderOperator(operator)}
        </Panel>
      )
    })
  }

  function renderViewOperator(operator: typeof operators[number]) {
    const selected = !!selectedOperator && selectedOperator.id === operator.id

    return (
      <OperatorExtra
        operator={operator}
        selected={selected}
        layerId={layerId}
      />
    )
  }

  function renderOperator(operator: typeof operators[0]) {
    const { id, __typename } = operator
    const Operator = operatorComponent(__typename as OperatorType)
    return (
      <div id={`operator-${id}`}>
        <Operator id={id} />
      </div>
    )
  }
}
