import * as React from 'react'
import {
  ProjectFragment,
  useGetProjectQuery,
  useUpdateProjectMutation,
  ProjectFragmentDoc,
} from '@lumn-color/hooks'
import { Field, FieldProps } from 'components/Field'
import { createUpdateFieldHook } from 'hooks/useUpdateField'
import { Omit } from 'utils'

type Project = ProjectFragment

export const useUpdateProjectField = createUpdateFieldHook<Project>({
  __typename: 'Project',
  fragmentDoc: ProjectFragmentDoc,
  queryName: 'project',
  mutationName: 'updateProject',
  useGetQuery: useGetProjectQuery,
  useUpdateMutation: useUpdateProjectMutation,
})

type EntityFieldProps = Omit<FieldProps<Project>, 'useUpdateField'>

function ProjectField(props: EntityFieldProps) {
  return (
    <Field<Project>
      {...props}
      id={props.id}
      useUpdateField={useUpdateProjectField}
    />
  )
}

const fields: Omit<EntityFieldProps, 'id'>[] = [
  {
    label: 'Name',
    fields: ['name'],
  },
  {
    label: 'Resolution',
    type: 'number',
    fields: ['resolutionW', 'resolutionH'],
  },
]

export const ProjectView = React.memo(function({
  projectId,
}: {
  projectId: string
}) {
  return <>{entityFields()}</>

  function entityFields() {
    return fields.map((field, index) => (
      <ProjectField key={index} id={projectId} {...field} />
    ))
  }
})
