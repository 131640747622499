import {
  useCreateFullProjectMutation,
  useGetFullProjectQuery,
  ListProjectsQuery,
  ListProjectsDocument,
} from '@lumn-color/hooks'
import { omit } from 'lodash'
import { useState, useCallback, useRef, useEffect } from 'react'
import { oc } from 'ts-optchain'
import { autoId, Dictionary, MutationHookVariablesData } from 'utils'
import produce from 'immer'
import { populateIdLookup, buildFullLayer } from './useCopyLayer'

export function useCopyProject({ projectId }: { projectId: string }) {
  const [loadProject, setLoadProject] = useState(false)
  const { data } = useGetFullProjectQuery({
    variables: { where: { id: projectId } },
    skip: !loadProject,
  })

  const project = oc(data).project()
  const projectRef = useRef<typeof project | null>(null)

  useEffect(() => {
    projectRef.current = project
  }, [project])

  const createFullProject = useCreateFullProject()

  const copyProject = useCallback(() => {
    if (!projectRef.current) return null

    const idLookup: Dictionary<string> = {}

    populateIdLookup(projectRef.current, idLookup)

    createFullProject({
      ...omit(projectRef.current, ['id', 'name', '__typename', 'layers']),
      name: `${projectRef.current.name} Copy`,
      layers: {
        create: oc(projectRef.current)
          .layers([])
          .map(layer => {
            return buildFullLayer(layer, idLookup)
          }),
      },
    })
  }, [createFullProject])

  const prepareCopyProject = () => setLoadProject(true)

  return { prepareCopyProject, copyProject }
}

function useCreateFullProject() {
  const create = useCreateFullProjectMutation()
  return useCallback(
    (data: MutationHookVariablesData<typeof useCreateFullProjectMutation>) => {
      const variables = {
        data: {
          id: autoId(),
          ...data,
        },
      }
      return create({
        variables,
        update(proxy, result) {
          const queryOptions = { query: ListProjectsDocument }
          const query = proxy.readQuery<ListProjectsQuery>(queryOptions)!
          proxy.writeQuery({
            ...queryOptions,
            data: produce(query, draft => {
              draft.projects.push(result.data!.createProject)
            }),
          })
        },
      })
    },
    [create],
  )
}
