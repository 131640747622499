import React, { useMemo } from 'react'

export type TStop = { id: string; pos: number; color: string }

export type TPalette = TStop[]

type Props = {
  width: number
  height: number
  palette: TPalette
}

export function Palette({ palette, width, height }: Props) {
  const gradientId = randomId()
  const sortedPalette = useMemo(() => [...palette].sort(compare), [palette])

  return (
    <div className="palette" style={{ width, height }}>
      <svg width="100%" height="100%">
        <defs>
          <linearGradient id={gradientId} x1="0" y1="0.5" x2="1" y2="0.5">
            {' '}
            {sortedPalette.map(stop => (
              <stop
                key={stop.id}
                offset={stop.pos}
                style={{ stopColor: stop.color, stopOpacity: 1 }}
              />
            ))}
          </linearGradient>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" fill={gradientUrl()} />
      </svg>
    </div>
  )

  function gradientUrl() {
    return `url(#${gradientId})`
  }
}

function randomId() {
  return `_${Math.random()
    .toString(36)
    .substr(2, 9)}`
}

function compare(stop1: TStop, stop2: TStop) {
  return stop1.pos - stop2.pos
}
