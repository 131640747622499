import * as React from 'react'
import { Field as IField, Model } from 'types/model'
import { useUpdateFieldSimple } from 'hooks/useUpdateFieldSimple'
import { Label } from './Label'
import { ColorPicker } from './ColorPicker'

type FieldUpdater = ReturnType<typeof useUpdateFieldSimple>

type Props<M extends Model, F extends IField<M>> = {
  label: string
  fieldUpdaters: FieldUpdater[]
}

// TODO make single update to the server instead of 3 (R, G, B)

export const ColorField = React.memo(function<
  M extends Model,
  F extends IField<M>
>(props: Props<M, F>) {
  return (
    <div style={{ marginBottom: 8 }}>
      <Label title={props.label} syncing={syncing()} />
      <div style={{ display: 'flex' }}>{renderInput()}</div>
    </div>
  )

  function renderInput() {
    const updaters = {
      r: props.fieldUpdaters[0],
      g: props.fieldUpdaters[1],
      b: props.fieldUpdaters[2],
      a: props.fieldUpdaters[3],
    }
    const color = {
      r: updaters.r.value,
      g: updaters.g.value,
      b: updaters.b.value,
      a: updaters.a.value,
    }
    return (
      <ColorPicker
        color={color}
        onChange={({ rgb }) => {
          const { r, g, b, a } = rgb
          updaters.r.setValue(r)
          updaters.g.setValue(g)
          updaters.b.setValue(b)
          updaters.a.setValue(a)
        }}
      />
    )
  }

  function syncing() {
    return props.fieldUpdaters.some(_ => _.syncing)
  }
})
