import { useListViewersQuery, Viewer } from '@lumn-color/hooks'
import { Modal } from 'antd'
import { useCreateViewer } from 'api'
import { CardList } from 'components/CardList'
import React, { useCallback } from 'react'
import { oc } from 'ts-optchain'
import { ViewerCard } from './ViewerCard'

const { confirm } = Modal

export function ViewerList() {
  const { loading, data } = useListViewersQuery()
  const createViewer = useCreateViewer()
  const viewers = oc(data).viewers([])

  const onCreateViewer = useCallback(() => {
    return confirm({
      title: 'Do you want to create a new viewer?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        createViewer({
          name: 'Viewer',
        })
      },
    })
  }, [createViewer])

  return (
    <CardList
      onCreate={onCreateViewer}
      loading={loading}
      dataSource={viewers}
      renderItem={renderItem}
    />
  )

  function renderItem(viewer: Viewer) {
    return <ViewerCard viewer={viewer} />
  }
}
