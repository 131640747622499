"use strict";

var __makeTemplateObject = this && this.__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

exports.__esModule = true;

var graphql_tag_1 = require("graphql-tag");

var ReactApolloHooks = require("react-apollo-hooks");

var CompositeOrderByInput;

(function (CompositeOrderByInput) {
  CompositeOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
  CompositeOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
  CompositeOrderByInput["ExpRotateAsc"] = "exp_rotate_ASC";
  CompositeOrderByInput["ExpRotateDesc"] = "exp_rotate_DESC";
  CompositeOrderByInput["ExpScaleXAsc"] = "exp_scaleX_ASC";
  CompositeOrderByInput["ExpScaleXDesc"] = "exp_scaleX_DESC";
  CompositeOrderByInput["ExpScaleYAsc"] = "exp_scaleY_ASC";
  CompositeOrderByInput["ExpScaleYDesc"] = "exp_scaleY_DESC";
  CompositeOrderByInput["ExpTranslateXAsc"] = "exp_translateX_ASC";
  CompositeOrderByInput["ExpTranslateXDesc"] = "exp_translateX_DESC";
  CompositeOrderByInput["ExpTranslateYAsc"] = "exp_translateY_ASC";
  CompositeOrderByInput["ExpTranslateYDesc"] = "exp_translateY_DESC";
  CompositeOrderByInput["ExtendAsc"] = "extend_ASC";
  CompositeOrderByInput["ExtendDesc"] = "extend_DESC";
  CompositeOrderByInput["FitAsc"] = "fit_ASC";
  CompositeOrderByInput["FitDesc"] = "fit_DESC";
  CompositeOrderByInput["FixedLayerAsc"] = "fixedLayer_ASC";
  CompositeOrderByInput["FixedLayerDesc"] = "fixedLayer_DESC";
  CompositeOrderByInput["IdAsc"] = "id_ASC";
  CompositeOrderByInput["IdDesc"] = "id_DESC";
  CompositeOrderByInput["Input1Asc"] = "input1_ASC";
  CompositeOrderByInput["Input1Desc"] = "input1_DESC";
  CompositeOrderByInput["Input2Asc"] = "input2_ASC";
  CompositeOrderByInput["Input2Desc"] = "input2_DESC";
  CompositeOrderByInput["JustifyHorizontalAsc"] = "justifyHorizontal_ASC";
  CompositeOrderByInput["JustifyHorizontalDesc"] = "justifyHorizontal_DESC";
  CompositeOrderByInput["JustifyVerticalAsc"] = "justifyVertical_ASC";
  CompositeOrderByInput["JustifyVerticalDesc"] = "justifyVertical_DESC";
  CompositeOrderByInput["NameAsc"] = "name_ASC";
  CompositeOrderByInput["NameDesc"] = "name_DESC";
  CompositeOrderByInput["OperationAsc"] = "operation_ASC";
  CompositeOrderByInput["OperationDesc"] = "operation_DESC";
  CompositeOrderByInput["RotateAsc"] = "rotate_ASC";
  CompositeOrderByInput["RotateDesc"] = "rotate_DESC";
  CompositeOrderByInput["ScaleXAsc"] = "scaleX_ASC";
  CompositeOrderByInput["ScaleXDesc"] = "scaleX_DESC";
  CompositeOrderByInput["ScaleYAsc"] = "scaleY_ASC";
  CompositeOrderByInput["ScaleYDesc"] = "scaleY_DESC";
  CompositeOrderByInput["TransformOrderAsc"] = "transformOrder_ASC";
  CompositeOrderByInput["TransformOrderDesc"] = "transformOrder_DESC";
  CompositeOrderByInput["TranslateXAsc"] = "translateX_ASC";
  CompositeOrderByInput["TranslateXDesc"] = "translateX_DESC";
  CompositeOrderByInput["TranslateYAsc"] = "translateY_ASC";
  CompositeOrderByInput["TranslateYDesc"] = "translateY_DESC";
  CompositeOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
  CompositeOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(CompositeOrderByInput = exports.CompositeOrderByInput || (exports.CompositeOrderByInput = {}));

var ConstantOrderByInput;

(function (ConstantOrderByInput) {
  ConstantOrderByInput["ColorAAsc"] = "colorA_ASC";
  ConstantOrderByInput["ColorADesc"] = "colorA_DESC";
  ConstantOrderByInput["ColorBAsc"] = "colorB_ASC";
  ConstantOrderByInput["ColorBDesc"] = "colorB_DESC";
  ConstantOrderByInput["ColorGAsc"] = "colorG_ASC";
  ConstantOrderByInput["ColorGDesc"] = "colorG_DESC";
  ConstantOrderByInput["ColorRAsc"] = "colorR_ASC";
  ConstantOrderByInput["ColorRDesc"] = "colorR_DESC";
  ConstantOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
  ConstantOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
  ConstantOrderByInput["ExpColorAAsc"] = "exp_colorA_ASC";
  ConstantOrderByInput["ExpColorADesc"] = "exp_colorA_DESC";
  ConstantOrderByInput["ExpColorBAsc"] = "exp_colorB_ASC";
  ConstantOrderByInput["ExpColorBDesc"] = "exp_colorB_DESC";
  ConstantOrderByInput["ExpColorGAsc"] = "exp_colorG_ASC";
  ConstantOrderByInput["ExpColorGDesc"] = "exp_colorG_DESC";
  ConstantOrderByInput["ExpColorRAsc"] = "exp_colorR_ASC";
  ConstantOrderByInput["ExpColorRDesc"] = "exp_colorR_DESC";
  ConstantOrderByInput["IdAsc"] = "id_ASC";
  ConstantOrderByInput["IdDesc"] = "id_DESC";
  ConstantOrderByInput["NameAsc"] = "name_ASC";
  ConstantOrderByInput["NameDesc"] = "name_DESC";
  ConstantOrderByInput["ResolutionHAsc"] = "resolutionH_ASC";
  ConstantOrderByInput["ResolutionHDesc"] = "resolutionH_DESC";
  ConstantOrderByInput["ResolutionWAsc"] = "resolutionW_ASC";
  ConstantOrderByInput["ResolutionWDesc"] = "resolutionW_DESC";
  ConstantOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
  ConstantOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(ConstantOrderByInput = exports.ConstantOrderByInput || (exports.ConstantOrderByInput = {}));

var DisplaceOrderByInput;

(function (DisplaceOrderByInput) {
  DisplaceOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
  DisplaceOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
  DisplaceOrderByInput["DisplaceWeightXAsc"] = "displaceWeightX_ASC";
  DisplaceOrderByInput["DisplaceWeightXDesc"] = "displaceWeightX_DESC";
  DisplaceOrderByInput["DisplaceWeightYAsc"] = "displaceWeightY_ASC";
  DisplaceOrderByInput["DisplaceWeightYDesc"] = "displaceWeightY_DESC";
  DisplaceOrderByInput["ExtendAsc"] = "extend_ASC";
  DisplaceOrderByInput["ExtendDesc"] = "extend_DESC";
  DisplaceOrderByInput["HorizontalSourceAsc"] = "horizontalSource_ASC";
  DisplaceOrderByInput["HorizontalSourceDesc"] = "horizontalSource_DESC";
  DisplaceOrderByInput["IdAsc"] = "id_ASC";
  DisplaceOrderByInput["IdDesc"] = "id_DESC";
  DisplaceOrderByInput["Input1Asc"] = "input1_ASC";
  DisplaceOrderByInput["Input1Desc"] = "input1_DESC";
  DisplaceOrderByInput["Input2Asc"] = "input2_ASC";
  DisplaceOrderByInput["Input2Desc"] = "input2_DESC";
  DisplaceOrderByInput["NameAsc"] = "name_ASC";
  DisplaceOrderByInput["NameDesc"] = "name_DESC";
  DisplaceOrderByInput["OffsetWeightAsc"] = "offsetWeight_ASC";
  DisplaceOrderByInput["OffsetWeightDesc"] = "offsetWeight_DESC";
  DisplaceOrderByInput["OffsetXAsc"] = "offsetX_ASC";
  DisplaceOrderByInput["OffsetXDesc"] = "offsetX_DESC";
  DisplaceOrderByInput["OffsetYAsc"] = "offsetY_ASC";
  DisplaceOrderByInput["OffsetYDesc"] = "offsetY_DESC";
  DisplaceOrderByInput["SourceMidpointXAsc"] = "sourceMidpointX_ASC";
  DisplaceOrderByInput["SourceMidpointXDesc"] = "sourceMidpointX_DESC";
  DisplaceOrderByInput["SourceMidpointYAsc"] = "sourceMidpointY_ASC";
  DisplaceOrderByInput["SourceMidpointYDesc"] = "sourceMidpointY_DESC";
  DisplaceOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
  DisplaceOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
  DisplaceOrderByInput["UvWeightAsc"] = "uvWeight_ASC";
  DisplaceOrderByInput["UvWeightDesc"] = "uvWeight_DESC";
  DisplaceOrderByInput["VerticalSourceAsc"] = "verticalSource_ASC";
  DisplaceOrderByInput["VerticalSourceDesc"] = "verticalSource_DESC";
})(DisplaceOrderByInput = exports.DisplaceOrderByInput || (exports.DisplaceOrderByInput = {}));

var Extend;

(function (Extend) {
  Extend["Hold"] = "Hold";
  Extend["Mirror"] = "Mirror";
  Extend["Repeat"] = "Repeat";
  Extend["Zero"] = "Zero";
})(Extend = exports.Extend || (exports.Extend = {}));

var Fit;

(function (Fit) {
  Fit["Fill"] = "Fill";
  Fit["FitBest"] = "FitBest";
  Fit["FitHorizontal"] = "FitHorizontal";
  Fit["FitOutside"] = "FitOutside";
  Fit["FitVertical"] = "FitVertical";
  Fit["NativeResolution"] = "NativeResolution";
})(Fit = exports.Fit || (exports.Fit = {}));

var InterpolateNotches;

(function (InterpolateNotches) {
  InterpolateNotches["EaseInEaseOut"] = "Ease_in_Ease_out";
  InterpolateNotches["Hermite"] = "Hermite";
  InterpolateNotches["Linear"] = "Linear";
  InterpolateNotches["Step"] = "Step";
})(InterpolateNotches = exports.InterpolateNotches || (exports.InterpolateNotches = {}));

var JustifyHorizontal;

(function (JustifyHorizontal) {
  JustifyHorizontal["Center"] = "Center";
  JustifyHorizontal["Left"] = "Left";
  JustifyHorizontal["Right"] = "Right";
})(JustifyHorizontal = exports.JustifyHorizontal || (exports.JustifyHorizontal = {}));

var JustifyVertical;

(function (JustifyVertical) {
  JustifyVertical["Bottom"] = "Bottom";
  JustifyVertical["Center"] = "Center";
  JustifyVertical["Top"] = "Top";
})(JustifyVertical = exports.JustifyVertical || (exports.JustifyVertical = {}));

var LayerOrderByInput;

(function (LayerOrderByInput) {
  LayerOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
  LayerOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
  LayerOrderByInput["ExpTranslateXAsc"] = "exp_translateX_ASC";
  LayerOrderByInput["ExpTranslateXDesc"] = "exp_translateX_DESC";
  LayerOrderByInput["ExpTranslateYAsc"] = "exp_translateY_ASC";
  LayerOrderByInput["ExpTranslateYDesc"] = "exp_translateY_DESC";
  LayerOrderByInput["IdAsc"] = "id_ASC";
  LayerOrderByInput["IdDesc"] = "id_DESC";
  LayerOrderByInput["InputAsc"] = "input_ASC";
  LayerOrderByInput["InputDesc"] = "input_DESC";
  LayerOrderByInput["NameAsc"] = "name_ASC";
  LayerOrderByInput["NameDesc"] = "name_DESC";
  LayerOrderByInput["ResolutionHAsc"] = "resolutionH_ASC";
  LayerOrderByInput["ResolutionHDesc"] = "resolutionH_DESC";
  LayerOrderByInput["ResolutionWAsc"] = "resolutionW_ASC";
  LayerOrderByInput["ResolutionWDesc"] = "resolutionW_DESC";
  LayerOrderByInput["TranslateXAsc"] = "translateX_ASC";
  LayerOrderByInput["TranslateXDesc"] = "translateX_DESC";
  LayerOrderByInput["TranslateYAsc"] = "translateY_ASC";
  LayerOrderByInput["TranslateYDesc"] = "translateY_DESC";
  LayerOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
  LayerOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
  LayerOrderByInput["ZIndexAsc"] = "zIndex_ASC";
  LayerOrderByInput["ZIndexDesc"] = "zIndex_DESC";
})(LayerOrderByInput = exports.LayerOrderByInput || (exports.LayerOrderByInput = {}));

var MutationType;

(function (MutationType) {
  MutationType["Created"] = "CREATED";
  MutationType["Deleted"] = "DELETED";
  MutationType["Updated"] = "UPDATED";
})(MutationType = exports.MutationType || (exports.MutationType = {}));

var NoiseOrderByInput;

(function (NoiseOrderByInput) {
  NoiseOrderByInput["AmplitudeAsc"] = "amplitude_ASC";
  NoiseOrderByInput["AmplitudeDesc"] = "amplitude_DESC";
  NoiseOrderByInput["AspectCorrectAsc"] = "aspectCorrect_ASC";
  NoiseOrderByInput["AspectCorrectDesc"] = "aspectCorrect_DESC";
  NoiseOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
  NoiseOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
  NoiseOrderByInput["ExpAmplitudeAsc"] = "exp_amplitude_ASC";
  NoiseOrderByInput["ExpAmplitudeDesc"] = "exp_amplitude_DESC";
  NoiseOrderByInput["ExpAspectCorrectAsc"] = "exp_aspectCorrect_ASC";
  NoiseOrderByInput["ExpAspectCorrectDesc"] = "exp_aspectCorrect_DESC";
  NoiseOrderByInput["ExpExponentAsc"] = "exp_exponent_ASC";
  NoiseOrderByInput["ExpExponentDesc"] = "exp_exponent_DESC";
  NoiseOrderByInput["ExpHarmonicGainAsc"] = "exp_harmonicGain_ASC";
  NoiseOrderByInput["ExpHarmonicGainDesc"] = "exp_harmonicGain_DESC";
  NoiseOrderByInput["ExpHarmonicsAsc"] = "exp_harmonics_ASC";
  NoiseOrderByInput["ExpHarmonicsDesc"] = "exp_harmonics_DESC";
  NoiseOrderByInput["ExpHarmonicSpreadAsc"] = "exp_harmonicSpread_ASC";
  NoiseOrderByInput["ExpHarmonicSpreadDesc"] = "exp_harmonicSpread_DESC";
  NoiseOrderByInput["ExpMonochromeAsc"] = "exp_monochrome_ASC";
  NoiseOrderByInput["ExpMonochromeDesc"] = "exp_monochrome_DESC";
  NoiseOrderByInput["ExpOffsetAsc"] = "exp_offset_ASC";
  NoiseOrderByInput["ExpOffsetDesc"] = "exp_offset_DESC";
  NoiseOrderByInput["ExpPeriodAsc"] = "exp_period_ASC";
  NoiseOrderByInput["ExpPeriodDesc"] = "exp_period_DESC";
  NoiseOrderByInput["ExpRotateXAsc"] = "exp_rotateX_ASC";
  NoiseOrderByInput["ExpRotateXDesc"] = "exp_rotateX_DESC";
  NoiseOrderByInput["ExpRotateYAsc"] = "exp_rotateY_ASC";
  NoiseOrderByInput["ExpRotateYDesc"] = "exp_rotateY_DESC";
  NoiseOrderByInput["ExpRotateZAsc"] = "exp_rotateZ_ASC";
  NoiseOrderByInput["ExpRotateZDesc"] = "exp_rotateZ_DESC";
  NoiseOrderByInput["ExpScaleXAsc"] = "exp_scaleX_ASC";
  NoiseOrderByInput["ExpScaleXDesc"] = "exp_scaleX_DESC";
  NoiseOrderByInput["ExpScaleYAsc"] = "exp_scaleY_ASC";
  NoiseOrderByInput["ExpScaleYDesc"] = "exp_scaleY_DESC";
  NoiseOrderByInput["ExpScaleZAsc"] = "exp_scaleZ_ASC";
  NoiseOrderByInput["ExpScaleZDesc"] = "exp_scaleZ_DESC";
  NoiseOrderByInput["ExpSeedAsc"] = "exp_seed_ASC";
  NoiseOrderByInput["ExpSeedDesc"] = "exp_seed_DESC";
  NoiseOrderByInput["ExpTranslateXAsc"] = "exp_translateX_ASC";
  NoiseOrderByInput["ExpTranslateXDesc"] = "exp_translateX_DESC";
  NoiseOrderByInput["ExpTranslateYAsc"] = "exp_translateY_ASC";
  NoiseOrderByInput["ExpTranslateYDesc"] = "exp_translateY_DESC";
  NoiseOrderByInput["ExpTranslateZAsc"] = "exp_translateZ_ASC";
  NoiseOrderByInput["ExpTranslateZDesc"] = "exp_translateZ_DESC";
  NoiseOrderByInput["ExponentAsc"] = "exponent_ASC";
  NoiseOrderByInput["ExponentDesc"] = "exponent_DESC";
  NoiseOrderByInput["HarmonicGainAsc"] = "harmonicGain_ASC";
  NoiseOrderByInput["HarmonicGainDesc"] = "harmonicGain_DESC";
  NoiseOrderByInput["HarmonicsAsc"] = "harmonics_ASC";
  NoiseOrderByInput["HarmonicsDesc"] = "harmonics_DESC";
  NoiseOrderByInput["HarmonicSpreadAsc"] = "harmonicSpread_ASC";
  NoiseOrderByInput["HarmonicSpreadDesc"] = "harmonicSpread_DESC";
  NoiseOrderByInput["IdAsc"] = "id_ASC";
  NoiseOrderByInput["IdDesc"] = "id_DESC";
  NoiseOrderByInput["MonochromeAsc"] = "monochrome_ASC";
  NoiseOrderByInput["MonochromeDesc"] = "monochrome_DESC";
  NoiseOrderByInput["NameAsc"] = "name_ASC";
  NoiseOrderByInput["NameDesc"] = "name_DESC";
  NoiseOrderByInput["OffsetAsc"] = "offset_ASC";
  NoiseOrderByInput["OffsetDesc"] = "offset_DESC";
  NoiseOrderByInput["PeriodAsc"] = "period_ASC";
  NoiseOrderByInput["PeriodDesc"] = "period_DESC";
  NoiseOrderByInput["ResolutionHAsc"] = "resolutionH_ASC";
  NoiseOrderByInput["ResolutionHDesc"] = "resolutionH_DESC";
  NoiseOrderByInput["ResolutionWAsc"] = "resolutionW_ASC";
  NoiseOrderByInput["ResolutionWDesc"] = "resolutionW_DESC";
  NoiseOrderByInput["RotateXAsc"] = "rotateX_ASC";
  NoiseOrderByInput["RotateXDesc"] = "rotateX_DESC";
  NoiseOrderByInput["RotateYAsc"] = "rotateY_ASC";
  NoiseOrderByInput["RotateYDesc"] = "rotateY_DESC";
  NoiseOrderByInput["RotateZAsc"] = "rotateZ_ASC";
  NoiseOrderByInput["RotateZDesc"] = "rotateZ_DESC";
  NoiseOrderByInput["ScaleXAsc"] = "scaleX_ASC";
  NoiseOrderByInput["ScaleXDesc"] = "scaleX_DESC";
  NoiseOrderByInput["ScaleYAsc"] = "scaleY_ASC";
  NoiseOrderByInput["ScaleYDesc"] = "scaleY_DESC";
  NoiseOrderByInput["ScaleZAsc"] = "scaleZ_ASC";
  NoiseOrderByInput["ScaleZDesc"] = "scaleZ_DESC";
  NoiseOrderByInput["SeedAsc"] = "seed_ASC";
  NoiseOrderByInput["SeedDesc"] = "seed_DESC";
  NoiseOrderByInput["TranslateXAsc"] = "translateX_ASC";
  NoiseOrderByInput["TranslateXDesc"] = "translateX_DESC";
  NoiseOrderByInput["TranslateYAsc"] = "translateY_ASC";
  NoiseOrderByInput["TranslateYDesc"] = "translateY_DESC";
  NoiseOrderByInput["TranslateZAsc"] = "translateZ_ASC";
  NoiseOrderByInput["TranslateZDesc"] = "translateZ_DESC";
  NoiseOrderByInput["TypeAsc"] = "type_ASC";
  NoiseOrderByInput["TypeDesc"] = "type_DESC";
  NoiseOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
  NoiseOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(NoiseOrderByInput = exports.NoiseOrderByInput || (exports.NoiseOrderByInput = {}));

var NoiseType;

(function (NoiseType) {
  NoiseType["Alligator"] = "Alligator";
  NoiseType["HarmonicSummation"] = "Harmonic_Summation";
  NoiseType["Hermite"] = "Hermite";
  NoiseType["Perlin_2DGpu"] = "Perlin_2D_GPU";
  NoiseType["Perlin_3DGpu"] = "Perlin_3D_GPU";
  NoiseType["Perlin_4DGpu"] = "Perlin_4D_GPU";
  NoiseType["Random"] = "Random";
  NoiseType["RandomGpu"] = "Random_GPU";
  NoiseType["Simplex_2DGpu"] = "Simplex_2D_GPU";
  NoiseType["Simplex_3DGpu"] = "Simplex_3D_GPU";
  NoiseType["Simplex_4DGpu"] = "Simplex_4D_GPU";
  NoiseType["Sparse"] = "Sparse";
})(NoiseType = exports.NoiseType || (exports.NoiseType = {}));

var Operation;

(function (Operation) {
  Operation["Add"] = "Add";
  Operation["Atop"] = "Atop";
  Operation["Average"] = "Average";
  Operation["Brightest"] = "Brightest";
  Operation["BurnColor"] = "BurnColor";
  Operation["BurnLinear"] = "BurnLinear";
  Operation["ChromaDifference"] = "ChromaDifference";
  Operation["Color"] = "Color";
  Operation["DarkerColor"] = "DarkerColor";
  Operation["Difference"] = "Difference";
  Operation["Dimmest"] = "Dimmest";
  Operation["Divide"] = "Divide";
  Operation["Dodge"] = "Dodge";
  Operation["Exclude"] = "Exclude";
  Operation["Freeze"] = "Freeze";
  Operation["Glow"] = "Glow";
  Operation["HardLight"] = "HardLight";
  Operation["HardMix"] = "HardMix";
  Operation["Heat"] = "Heat";
  Operation["Hue"] = "Hue";
  Operation["Inside"] = "Inside";
  Operation["InsideLuminance"] = "InsideLuminance";
  Operation["Inverse"] = "Inverse";
  Operation["LighterColor"] = "LighterColor";
  Operation["LinearLight"] = "LinearLight";
  Operation["LuminanceDifference"] = "LuminanceDifference";
  Operation["Maximum"] = "Maximum";
  Operation["Minimum"] = "Minimum";
  Operation["Multiply"] = "Multiply";
  Operation["Negate"] = "Negate";
  Operation["Outside"] = "Outside";
  Operation["OutsideLuminance"] = "OutsideLuminance";
  Operation["Over"] = "Over";
  Operation["Overlay"] = "Overlay";
  Operation["Pinlight"] = "Pinlight";
  Operation["Reflect"] = "Reflect";
  Operation["Screen"] = "Screen";
  Operation["SoftLight"] = "SoftLight";
  Operation["StencilLuminance"] = "StencilLuminance";
  Operation["Subtract"] = "Subtract";
  Operation["Subtractive"] = "Subtractive";
  Operation["Under"] = "Under";
  Operation["VividLight"] = "VividLight";
  Operation["Xor"] = "Xor";
  Operation["YFilm"] = "YFilm";
  Operation["ZFilm"] = "ZFilm";
})(Operation = exports.Operation || (exports.Operation = {}));

var ProjectOrderByInput;

(function (ProjectOrderByInput) {
  ProjectOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
  ProjectOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
  ProjectOrderByInput["IdAsc"] = "id_ASC";
  ProjectOrderByInput["IdDesc"] = "id_DESC";
  ProjectOrderByInput["NameAsc"] = "name_ASC";
  ProjectOrderByInput["NameDesc"] = "name_DESC";
  ProjectOrderByInput["ResolutionHAsc"] = "resolutionH_ASC";
  ProjectOrderByInput["ResolutionHDesc"] = "resolutionH_DESC";
  ProjectOrderByInput["ResolutionWAsc"] = "resolutionW_ASC";
  ProjectOrderByInput["ResolutionWDesc"] = "resolutionW_DESC";
  ProjectOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
  ProjectOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(ProjectOrderByInput = exports.ProjectOrderByInput || (exports.ProjectOrderByInput = {}));

var RampKeyOrderByInput;

(function (RampKeyOrderByInput) {
  RampKeyOrderByInput["ColorAAsc"] = "colorA_ASC";
  RampKeyOrderByInput["ColorADesc"] = "colorA_DESC";
  RampKeyOrderByInput["ColorBAsc"] = "colorB_ASC";
  RampKeyOrderByInput["ColorBDesc"] = "colorB_DESC";
  RampKeyOrderByInput["ColorGAsc"] = "colorG_ASC";
  RampKeyOrderByInput["ColorGDesc"] = "colorG_DESC";
  RampKeyOrderByInput["ColorRAsc"] = "colorR_ASC";
  RampKeyOrderByInput["ColorRDesc"] = "colorR_DESC";
  RampKeyOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
  RampKeyOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
  RampKeyOrderByInput["IdAsc"] = "id_ASC";
  RampKeyOrderByInput["IdDesc"] = "id_DESC";
  RampKeyOrderByInput["PositionAsc"] = "position_ASC";
  RampKeyOrderByInput["PositionDesc"] = "position_DESC";
  RampKeyOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
  RampKeyOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(RampKeyOrderByInput = exports.RampKeyOrderByInput || (exports.RampKeyOrderByInput = {}));

var RampOrderByInput;

(function (RampOrderByInput) {
  RampOrderByInput["AntiAliasAsc"] = "antiAlias_ASC";
  RampOrderByInput["AntiAliasDesc"] = "antiAlias_DESC";
  RampOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
  RampOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
  RampOrderByInput["CurveTensionAsc"] = "curveTension_ASC";
  RampOrderByInput["CurveTensionDesc"] = "curveTension_DESC";
  RampOrderByInput["DitherAsc"] = "dither_ASC";
  RampOrderByInput["DitherDesc"] = "dither_DESC";
  RampOrderByInput["ExpAntiAliasAsc"] = "exp_antiAlias_ASC";
  RampOrderByInput["ExpAntiAliasDesc"] = "exp_antiAlias_DESC";
  RampOrderByInput["ExpCurveTensionAsc"] = "exp_curveTension_ASC";
  RampOrderByInput["ExpCurveTensionDesc"] = "exp_curveTension_DESC";
  RampOrderByInput["ExpDitherAsc"] = "exp_dither_ASC";
  RampOrderByInput["ExpDitherDesc"] = "exp_dither_DESC";
  RampOrderByInput["ExpExtendLeftAsc"] = "exp_extendLeft_ASC";
  RampOrderByInput["ExpExtendLeftDesc"] = "exp_extendLeft_DESC";
  RampOrderByInput["ExpExtendRightAsc"] = "exp_extendRight_ASC";
  RampOrderByInput["ExpExtendRightDesc"] = "exp_extendRight_DESC";
  RampOrderByInput["ExpInterpolateNotchesAsc"] = "exp_interpolateNotches_ASC";
  RampOrderByInput["ExpInterpolateNotchesDesc"] = "exp_interpolateNotches_DESC";
  RampOrderByInput["ExpMultiplyRgbByAlphaAsc"] = "exp_multiplyRgbByAlpha_ASC";
  RampOrderByInput["ExpMultiplyRgbByAlphaDesc"] = "exp_multiplyRgbByAlpha_DESC";
  RampOrderByInput["ExpPeriodAsc"] = "exp_period_ASC";
  RampOrderByInput["ExpPeriodDesc"] = "exp_period_DESC";
  RampOrderByInput["ExpPhaseAsc"] = "exp_phase_ASC";
  RampOrderByInput["ExpPhaseDesc"] = "exp_phase_DESC";
  RampOrderByInput["ExpPositionXAsc"] = "exp_positionX_ASC";
  RampOrderByInput["ExpPositionXDesc"] = "exp_positionX_DESC";
  RampOrderByInput["ExpPositionYAsc"] = "exp_positionY_ASC";
  RampOrderByInput["ExpPositionYDesc"] = "exp_positionY_DESC";
  RampOrderByInput["ExpResolutionHAsc"] = "exp_resolutionH_ASC";
  RampOrderByInput["ExpResolutionHDesc"] = "exp_resolutionH_DESC";
  RampOrderByInput["ExpResolutionWAsc"] = "exp_resolutionW_ASC";
  RampOrderByInput["ExpResolutionWDesc"] = "exp_resolutionW_DESC";
  RampOrderByInput["ExpTypeAsc"] = "exp_type_ASC";
  RampOrderByInput["ExpTypeDesc"] = "exp_type_DESC";
  RampOrderByInput["ExtendLeftAsc"] = "extendLeft_ASC";
  RampOrderByInput["ExtendLeftDesc"] = "extendLeft_DESC";
  RampOrderByInput["ExtendRightAsc"] = "extendRight_ASC";
  RampOrderByInput["ExtendRightDesc"] = "extendRight_DESC";
  RampOrderByInput["IdAsc"] = "id_ASC";
  RampOrderByInput["IdDesc"] = "id_DESC";
  RampOrderByInput["InterpolateNotchesAsc"] = "interpolateNotches_ASC";
  RampOrderByInput["InterpolateNotchesDesc"] = "interpolateNotches_DESC";
  RampOrderByInput["MultiplyRgbByAlphaAsc"] = "multiplyRgbByAlpha_ASC";
  RampOrderByInput["MultiplyRgbByAlphaDesc"] = "multiplyRgbByAlpha_DESC";
  RampOrderByInput["NameAsc"] = "name_ASC";
  RampOrderByInput["NameDesc"] = "name_DESC";
  RampOrderByInput["PeriodAsc"] = "period_ASC";
  RampOrderByInput["PeriodDesc"] = "period_DESC";
  RampOrderByInput["PhaseAsc"] = "phase_ASC";
  RampOrderByInput["PhaseDesc"] = "phase_DESC";
  RampOrderByInput["PositionXAsc"] = "positionX_ASC";
  RampOrderByInput["PositionXDesc"] = "positionX_DESC";
  RampOrderByInput["PositionYAsc"] = "positionY_ASC";
  RampOrderByInput["PositionYDesc"] = "positionY_DESC";
  RampOrderByInput["ResolutionHAsc"] = "resolutionH_ASC";
  RampOrderByInput["ResolutionHDesc"] = "resolutionH_DESC";
  RampOrderByInput["ResolutionWAsc"] = "resolutionW_ASC";
  RampOrderByInput["ResolutionWDesc"] = "resolutionW_DESC";
  RampOrderByInput["TypeAsc"] = "type_ASC";
  RampOrderByInput["TypeDesc"] = "type_DESC";
  RampOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
  RampOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(RampOrderByInput = exports.RampOrderByInput || (exports.RampOrderByInput = {}));

var RampType;

(function (RampType) {
  RampType["Circular"] = "Circular";
  RampType["Horizontal"] = "Horizontal";
  RampType["Radial"] = "Radial";
  RampType["Vertical"] = "Vertical";
})(RampType = exports.RampType || (exports.RampType = {}));

var RemapOrderByInput;

(function (RemapOrderByInput) {
  RemapOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
  RemapOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
  RemapOrderByInput["ExtendAsc"] = "extend_ASC";
  RemapOrderByInput["ExtendDesc"] = "extend_DESC";
  RemapOrderByInput["FlipHorizontalDirectionAsc"] = "flipHorizontalDirection_ASC";
  RemapOrderByInput["FlipHorizontalDirectionDesc"] = "flipHorizontalDirection_DESC";
  RemapOrderByInput["FlipVerticalDirectionAsc"] = "flipVerticalDirection_ASC";
  RemapOrderByInput["FlipVerticalDirectionDesc"] = "flipVerticalDirection_DESC";
  RemapOrderByInput["HorizontalSourceAsc"] = "horizontalSource_ASC";
  RemapOrderByInput["HorizontalSourceDesc"] = "horizontalSource_DESC";
  RemapOrderByInput["IdAsc"] = "id_ASC";
  RemapOrderByInput["IdDesc"] = "id_DESC";
  RemapOrderByInput["Input1Asc"] = "input1_ASC";
  RemapOrderByInput["Input1Desc"] = "input1_DESC";
  RemapOrderByInput["Input2Asc"] = "input2_ASC";
  RemapOrderByInput["Input2Desc"] = "input2_DESC";
  RemapOrderByInput["NameAsc"] = "name_ASC";
  RemapOrderByInput["NameDesc"] = "name_DESC";
  RemapOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
  RemapOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
  RemapOrderByInput["VerticalSourceAsc"] = "verticalSource_ASC";
  RemapOrderByInput["VerticalSourceDesc"] = "verticalSource_DESC";
})(RemapOrderByInput = exports.RemapOrderByInput || (exports.RemapOrderByInput = {}));

var Source;

(function (Source) {
  Source["Alpha"] = "Alpha";
  Source["Blue"] = "Blue";
  Source["Green"] = "Green";
  Source["None"] = "None";
  Source["Red"] = "Red";
})(Source = exports.Source || (exports.Source = {}));

var TransformOrder;

(function (TransformOrder) {
  TransformOrder["RotateScaleTranslate"] = "Rotate_Scale_Translate";
  TransformOrder["RotateTranslateScale"] = "Rotate_Translate_Scale";
  TransformOrder["ScaleRotateTranslate"] = "Scale_Rotate_Translate";
  TransformOrder["ScaleTranslateRotate"] = "Scale_Translate_Rotate";
  TransformOrder["TranslateRotateScale"] = "Translate_Rotate_Scale";
  TransformOrder["TranslateScaleRotate"] = "Translate_Scale_Rotate";
})(TransformOrder = exports.TransformOrder || (exports.TransformOrder = {}));

var TransformOrderByInput;

(function (TransformOrderByInput) {
  TransformOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
  TransformOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
  TransformOrderByInput["ExpRotateAsc"] = "exp_rotate_ASC";
  TransformOrderByInput["ExpRotateDesc"] = "exp_rotate_DESC";
  TransformOrderByInput["ExpScaleXAsc"] = "exp_scaleX_ASC";
  TransformOrderByInput["ExpScaleXDesc"] = "exp_scaleX_DESC";
  TransformOrderByInput["ExpScaleYAsc"] = "exp_scaleY_ASC";
  TransformOrderByInput["ExpScaleYDesc"] = "exp_scaleY_DESC";
  TransformOrderByInput["ExpTranslateXAsc"] = "exp_translateX_ASC";
  TransformOrderByInput["ExpTranslateXDesc"] = "exp_translateX_DESC";
  TransformOrderByInput["ExpTranslateYAsc"] = "exp_translateY_ASC";
  TransformOrderByInput["ExpTranslateYDesc"] = "exp_translateY_DESC";
  TransformOrderByInput["ExtendAsc"] = "extend_ASC";
  TransformOrderByInput["ExtendDesc"] = "extend_DESC";
  TransformOrderByInput["IdAsc"] = "id_ASC";
  TransformOrderByInput["IdDesc"] = "id_DESC";
  TransformOrderByInput["Input1Asc"] = "input1_ASC";
  TransformOrderByInput["Input1Desc"] = "input1_DESC";
  TransformOrderByInput["NameAsc"] = "name_ASC";
  TransformOrderByInput["NameDesc"] = "name_DESC";
  TransformOrderByInput["ResolutionHAsc"] = "resolutionH_ASC";
  TransformOrderByInput["ResolutionHDesc"] = "resolutionH_DESC";
  TransformOrderByInput["ResolutionWAsc"] = "resolutionW_ASC";
  TransformOrderByInput["ResolutionWDesc"] = "resolutionW_DESC";
  TransformOrderByInput["RotateAsc"] = "rotate_ASC";
  TransformOrderByInput["RotateDesc"] = "rotate_DESC";
  TransformOrderByInput["ScaleXAsc"] = "scaleX_ASC";
  TransformOrderByInput["ScaleXDesc"] = "scaleX_DESC";
  TransformOrderByInput["ScaleYAsc"] = "scaleY_ASC";
  TransformOrderByInput["ScaleYDesc"] = "scaleY_DESC";
  TransformOrderByInput["TransformOrderAsc"] = "transformOrder_ASC";
  TransformOrderByInput["TransformOrderDesc"] = "transformOrder_DESC";
  TransformOrderByInput["TranslateXAsc"] = "translateX_ASC";
  TransformOrderByInput["TranslateXDesc"] = "translateX_DESC";
  TransformOrderByInput["TranslateYAsc"] = "translateY_ASC";
  TransformOrderByInput["TranslateYDesc"] = "translateY_DESC";
  TransformOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
  TransformOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(TransformOrderByInput = exports.TransformOrderByInput || (exports.TransformOrderByInput = {}));

var ViewerOrderByInput;

(function (ViewerOrderByInput) {
  ViewerOrderByInput["CreatedAtAsc"] = "createdAt_ASC";
  ViewerOrderByInput["CreatedAtDesc"] = "createdAt_DESC";
  ViewerOrderByInput["IdAsc"] = "id_ASC";
  ViewerOrderByInput["IdDesc"] = "id_DESC";
  ViewerOrderByInput["NameAsc"] = "name_ASC";
  ViewerOrderByInput["NameDesc"] = "name_DESC";
  ViewerOrderByInput["UpdatedAtAsc"] = "updatedAt_ASC";
  ViewerOrderByInput["UpdatedAtDesc"] = "updatedAt_DESC";
})(ViewerOrderByInput = exports.ViewerOrderByInput || (exports.ViewerOrderByInput = {}));

exports.LayerFragmentDoc = graphql_tag_1["default"](templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment Layer on Layer {\n    id\n    name\n    resolutionW\n    resolutionH\n    translateX\n    translateY\n    exp_translateX\n    exp_translateY\n    zIndex\n    input\n  }\n"], ["\n  fragment Layer on Layer {\n    id\n    name\n    resolutionW\n    resolutionH\n    translateX\n    translateY\n    exp_translateX\n    exp_translateY\n    zIndex\n    input\n  }\n"])));
exports.ProjectFragmentDoc = graphql_tag_1["default"](templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  fragment Project on Project {\n    id\n    name\n    resolutionW\n    resolutionH\n  }\n"], ["\n  fragment Project on Project {\n    id\n    name\n    resolutionW\n    resolutionH\n  }\n"])));
exports.ViewerFragmentDoc = graphql_tag_1["default"](templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  fragment Viewer on Viewer {\n    id\n    name\n    project {\n      ...Project\n    }\n  }\n  ", "\n"], ["\n  fragment Viewer on Viewer {\n    id\n    name\n    project {\n      ...Project\n    }\n  }\n  ", "\n"])), exports.ProjectFragmentDoc);
exports.ConstantFragmentDoc = graphql_tag_1["default"](templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  fragment Constant on Constant {\n    id\n    name\n    resolutionW\n    resolutionH\n    colorR\n    colorG\n    colorB\n    colorA\n    exp_colorR\n    exp_colorG\n    exp_colorB\n    exp_colorA\n  }\n"], ["\n  fragment Constant on Constant {\n    id\n    name\n    resolutionW\n    resolutionH\n    colorR\n    colorG\n    colorB\n    colorA\n    exp_colorR\n    exp_colorG\n    exp_colorB\n    exp_colorA\n  }\n"])));
exports.NoiseFragmentDoc = graphql_tag_1["default"](templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  fragment Noise on Noise {\n    id\n    name\n    resolutionW\n    resolutionH\n    type\n    seed\n    period\n    harmonics\n    harmonicSpread\n    harmonicGain\n    exponent\n    amplitude\n    offset\n    monochrome\n    aspectCorrect\n    translateX\n    translateY\n    translateZ\n    scaleX\n    scaleY\n    scaleZ\n    rotateX\n    rotateY\n    rotateZ\n    exp_seed\n    exp_period\n    exp_harmonics\n    exp_harmonicSpread\n    exp_harmonicGain\n    exp_exponent\n    exp_amplitude\n    exp_offset\n    exp_monochrome\n    exp_aspectCorrect\n    exp_translateX\n    exp_translateY\n    exp_translateZ\n    exp_scaleX\n    exp_scaleY\n    exp_scaleZ\n    exp_rotateX\n    exp_rotateY\n    exp_rotateZ\n  }\n"], ["\n  fragment Noise on Noise {\n    id\n    name\n    resolutionW\n    resolutionH\n    type\n    seed\n    period\n    harmonics\n    harmonicSpread\n    harmonicGain\n    exponent\n    amplitude\n    offset\n    monochrome\n    aspectCorrect\n    translateX\n    translateY\n    translateZ\n    scaleX\n    scaleY\n    scaleZ\n    rotateX\n    rotateY\n    rotateZ\n    exp_seed\n    exp_period\n    exp_harmonics\n    exp_harmonicSpread\n    exp_harmonicGain\n    exp_exponent\n    exp_amplitude\n    exp_offset\n    exp_monochrome\n    exp_aspectCorrect\n    exp_translateX\n    exp_translateY\n    exp_translateZ\n    exp_scaleX\n    exp_scaleY\n    exp_scaleZ\n    exp_rotateX\n    exp_rotateY\n    exp_rotateZ\n  }\n"])));
exports.RampKeyFragmentDoc = graphql_tag_1["default"](templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  fragment RampKey on RampKey {\n    id\n    position\n    colorR\n    colorG\n    colorB\n    colorA\n  }\n"], ["\n  fragment RampKey on RampKey {\n    id\n    position\n    colorR\n    colorG\n    colorB\n    colorA\n  }\n"])));
exports.RampFragmentDoc = graphql_tag_1["default"](templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  fragment Ramp on Ramp {\n    id\n    name\n    resolutionW\n    resolutionH\n    positionX\n    positionY\n    type\n    phase\n    period\n    extendLeft\n    extendRight\n    interpolateNotches\n    curveTension\n    antiAlias\n    dither\n    multiplyRgbByAlpha\n    exp_positionX\n    exp_positionY\n    exp_type\n    exp_phase\n    exp_period\n    exp_extendLeft\n    exp_extendRight\n    exp_interpolateNotches\n    exp_curveTension\n    exp_antiAlias\n    exp_dither\n    exp_multiplyRgbByAlpha\n    keys {\n      ...RampKey\n    }\n  }\n  ", "\n"], ["\n  fragment Ramp on Ramp {\n    id\n    name\n    resolutionW\n    resolutionH\n    positionX\n    positionY\n    type\n    phase\n    period\n    extendLeft\n    extendRight\n    interpolateNotches\n    curveTension\n    antiAlias\n    dither\n    multiplyRgbByAlpha\n    exp_positionX\n    exp_positionY\n    exp_type\n    exp_phase\n    exp_period\n    exp_extendLeft\n    exp_extendRight\n    exp_interpolateNotches\n    exp_curveTension\n    exp_antiAlias\n    exp_dither\n    exp_multiplyRgbByAlpha\n    keys {\n      ...RampKey\n    }\n  }\n  ", "\n"])), exports.RampKeyFragmentDoc);
exports.RemapFragmentDoc = graphql_tag_1["default"](templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  fragment Remap on Remap {\n    id\n    name\n    horizontalSource\n    verticalSource\n    flipHorizontalDirection\n    flipVerticalDirection\n    extend\n    input1\n    input2\n  }\n"], ["\n  fragment Remap on Remap {\n    id\n    name\n    horizontalSource\n    verticalSource\n    flipHorizontalDirection\n    flipVerticalDirection\n    extend\n    input1\n    input2\n  }\n"])));
exports.DisplaceFragmentDoc = graphql_tag_1["default"](templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  fragment Displace on Displace {\n    id\n    name\n    horizontalSource\n    verticalSource\n    sourceMidpointX\n    sourceMidpointY\n    displaceWeightX\n    displaceWeightY\n    uvWeight\n    offsetX\n    offsetY\n    offsetWeight\n    extend\n    input1\n    input2\n  }\n"], ["\n  fragment Displace on Displace {\n    id\n    name\n    horizontalSource\n    verticalSource\n    sourceMidpointX\n    sourceMidpointY\n    displaceWeightX\n    displaceWeightY\n    uvWeight\n    offsetX\n    offsetY\n    offsetWeight\n    extend\n    input1\n    input2\n  }\n"])));
exports.TransformFragmentDoc = graphql_tag_1["default"](templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  fragment Transform on Transform {\n    id\n    name\n    resolutionW\n    resolutionH\n    translateX\n    translateY\n    rotate\n    scaleX\n    scaleY\n    exp_translateX\n    exp_translateY\n    exp_rotate\n    exp_scaleX\n    exp_scaleY\n    extend\n    transformOrder\n    input1\n  }\n"], ["\n  fragment Transform on Transform {\n    id\n    name\n    resolutionW\n    resolutionH\n    translateX\n    translateY\n    rotate\n    scaleX\n    scaleY\n    exp_translateX\n    exp_translateY\n    exp_rotate\n    exp_scaleX\n    exp_scaleY\n    extend\n    transformOrder\n    input1\n  }\n"])));
exports.CompositeFragmentDoc = graphql_tag_1["default"](templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  fragment Composite on Composite {\n    id\n    name\n    operation\n    translateX\n    translateY\n    rotate\n    scaleX\n    scaleY\n    exp_translateX\n    exp_translateY\n    exp_rotate\n    exp_scaleX\n    exp_scaleY\n    extend\n    transformOrder\n    fixedLayer\n    justifyHorizontal\n    justifyVertical\n    fit\n    input1\n    input2\n  }\n"], ["\n  fragment Composite on Composite {\n    id\n    name\n    operation\n    translateX\n    translateY\n    rotate\n    scaleX\n    scaleY\n    exp_translateX\n    exp_translateY\n    exp_rotate\n    exp_scaleX\n    exp_scaleY\n    extend\n    transformOrder\n    fixedLayer\n    justifyHorizontal\n    justifyVertical\n    fit\n    input1\n    input2\n  }\n"])));
exports.FullLayerFragmentDoc = graphql_tag_1["default"](templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  fragment FullLayer on Layer {\n    id\n    name\n    resolutionW\n    resolutionH\n    translateX\n    translateY\n    exp_translateX\n    exp_translateY\n    zIndex\n    input\n    constants {\n      ...Constant\n    }\n    noises {\n      ...Noise\n    }\n    ramps {\n      ...Ramp\n    }\n    remaps {\n      ...Remap\n    }\n    displaces {\n      ...Displace\n    }\n    transforms {\n      ...Transform\n    }\n    composites {\n      ...Composite\n    }\n  }\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  fragment FullLayer on Layer {\n    id\n    name\n    resolutionW\n    resolutionH\n    translateX\n    translateY\n    exp_translateX\n    exp_translateY\n    zIndex\n    input\n    constants {\n      ...Constant\n    }\n    noises {\n      ...Noise\n    }\n    ramps {\n      ...Ramp\n    }\n    remaps {\n      ...Remap\n    }\n    displaces {\n      ...Displace\n    }\n    transforms {\n      ...Transform\n    }\n    composites {\n      ...Composite\n    }\n  }\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), exports.ConstantFragmentDoc, exports.NoiseFragmentDoc, exports.RampFragmentDoc, exports.RemapFragmentDoc, exports.DisplaceFragmentDoc, exports.TransformFragmentDoc, exports.CompositeFragmentDoc);
exports.FullProjectFragmentDoc = graphql_tag_1["default"](templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  fragment FullProject on Project {\n    id\n    name\n    resolutionW\n    resolutionH\n    layers {\n      ...FullLayer\n    }\n  }\n  ", "\n"], ["\n  fragment FullProject on Project {\n    id\n    name\n    resolutionW\n    resolutionH\n    layers {\n      ...FullLayer\n    }\n  }\n  ", "\n"])), exports.FullLayerFragmentDoc);
exports.FullViewerFragmentDoc = graphql_tag_1["default"](templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  fragment FullViewer on Viewer {\n    id\n    name\n    project {\n      ...FullProject\n    }\n  }\n  ", "\n"], ["\n  fragment FullViewer on Viewer {\n    id\n    name\n    project {\n      ...FullProject\n    }\n  }\n  ", "\n"])), exports.FullProjectFragmentDoc);
exports.GetCompositeDocument = graphql_tag_1["default"](templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  query GetComposite($where: CompositeWhereUniqueInput!) {\n    composite(where: $where) {\n      ...Composite\n    }\n  }\n  ", "\n"], ["\n  query GetComposite($where: CompositeWhereUniqueInput!) {\n    composite(where: $where) {\n      ...Composite\n    }\n  }\n  ", "\n"])), exports.CompositeFragmentDoc);

function useGetCompositeQuery(baseOptions) {
  return ReactApolloHooks.useQuery(exports.GetCompositeDocument, baseOptions);
}

exports.useGetCompositeQuery = useGetCompositeQuery;
exports.CreateCompositeDocument = graphql_tag_1["default"](templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  mutation CreateComposite($data: CompositeCreateInput!) {\n    createComposite(data: $data) {\n      ...Composite\n    }\n  }\n  ", "\n"], ["\n  mutation CreateComposite($data: CompositeCreateInput!) {\n    createComposite(data: $data) {\n      ...Composite\n    }\n  }\n  ", "\n"])), exports.CompositeFragmentDoc);

function useCreateCompositeMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.CreateCompositeDocument, baseOptions);
}

exports.useCreateCompositeMutation = useCreateCompositeMutation;
exports.UpdateCompositeDocument = graphql_tag_1["default"](templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  mutation UpdateComposite(\n    $data: CompositeUpdateInput!\n    $where: CompositeWhereUniqueInput!\n  ) {\n    updateComposite(data: $data, where: $where) {\n      ...Composite\n    }\n  }\n  ", "\n"], ["\n  mutation UpdateComposite(\n    $data: CompositeUpdateInput!\n    $where: CompositeWhereUniqueInput!\n  ) {\n    updateComposite(data: $data, where: $where) {\n      ...Composite\n    }\n  }\n  ", "\n"])), exports.CompositeFragmentDoc);

function useUpdateCompositeMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.UpdateCompositeDocument, baseOptions);
}

exports.useUpdateCompositeMutation = useUpdateCompositeMutation;
exports.DeleteCompositeDocument = graphql_tag_1["default"](templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  mutation DeleteComposite($where: CompositeWhereUniqueInput!) {\n    deleteComposite(where: $where) {\n      ...Composite\n    }\n  }\n  ", "\n"], ["\n  mutation DeleteComposite($where: CompositeWhereUniqueInput!) {\n    deleteComposite(where: $where) {\n      ...Composite\n    }\n  }\n  ", "\n"])), exports.CompositeFragmentDoc);

function useDeleteCompositeMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.DeleteCompositeDocument, baseOptions);
}

exports.useDeleteCompositeMutation = useDeleteCompositeMutation;
exports.GetConstantDocument = graphql_tag_1["default"](templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  query GetConstant($where: ConstantWhereUniqueInput!) {\n    constant(where: $where) {\n      ...Constant\n    }\n  }\n  ", "\n"], ["\n  query GetConstant($where: ConstantWhereUniqueInput!) {\n    constant(where: $where) {\n      ...Constant\n    }\n  }\n  ", "\n"])), exports.ConstantFragmentDoc);

function useGetConstantQuery(baseOptions) {
  return ReactApolloHooks.useQuery(exports.GetConstantDocument, baseOptions);
}

exports.useGetConstantQuery = useGetConstantQuery;
exports.CreateConstantDocument = graphql_tag_1["default"](templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  mutation CreateConstant($data: ConstantCreateInput!) {\n    createConstant(data: $data) {\n      ...Constant\n    }\n  }\n  ", "\n"], ["\n  mutation CreateConstant($data: ConstantCreateInput!) {\n    createConstant(data: $data) {\n      ...Constant\n    }\n  }\n  ", "\n"])), exports.ConstantFragmentDoc);

function useCreateConstantMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.CreateConstantDocument, baseOptions);
}

exports.useCreateConstantMutation = useCreateConstantMutation;
exports.UpdateConstantDocument = graphql_tag_1["default"](templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  mutation UpdateConstant(\n    $data: ConstantUpdateInput!\n    $where: ConstantWhereUniqueInput!\n  ) {\n    updateConstant(data: $data, where: $where) {\n      ...Constant\n    }\n  }\n  ", "\n"], ["\n  mutation UpdateConstant(\n    $data: ConstantUpdateInput!\n    $where: ConstantWhereUniqueInput!\n  ) {\n    updateConstant(data: $data, where: $where) {\n      ...Constant\n    }\n  }\n  ", "\n"])), exports.ConstantFragmentDoc);

function useUpdateConstantMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.UpdateConstantDocument, baseOptions);
}

exports.useUpdateConstantMutation = useUpdateConstantMutation;
exports.DeleteConstantDocument = graphql_tag_1["default"](templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  mutation DeleteConstant($where: ConstantWhereUniqueInput!) {\n    deleteConstant(where: $where) {\n      ...Constant\n    }\n  }\n  ", "\n"], ["\n  mutation DeleteConstant($where: ConstantWhereUniqueInput!) {\n    deleteConstant(where: $where) {\n      ...Constant\n    }\n  }\n  ", "\n"])), exports.ConstantFragmentDoc);

function useDeleteConstantMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.DeleteConstantDocument, baseOptions);
}

exports.useDeleteConstantMutation = useDeleteConstantMutation;
exports.GetDisplaceDocument = graphql_tag_1["default"](templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  query GetDisplace($where: DisplaceWhereUniqueInput!) {\n    displace(where: $where) {\n      ...Displace\n    }\n  }\n  ", "\n"], ["\n  query GetDisplace($where: DisplaceWhereUniqueInput!) {\n    displace(where: $where) {\n      ...Displace\n    }\n  }\n  ", "\n"])), exports.DisplaceFragmentDoc);

function useGetDisplaceQuery(baseOptions) {
  return ReactApolloHooks.useQuery(exports.GetDisplaceDocument, baseOptions);
}

exports.useGetDisplaceQuery = useGetDisplaceQuery;
exports.CreateDisplaceDocument = graphql_tag_1["default"](templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  mutation CreateDisplace($data: DisplaceCreateInput!) {\n    createDisplace(data: $data) {\n      ...Displace\n    }\n  }\n  ", "\n"], ["\n  mutation CreateDisplace($data: DisplaceCreateInput!) {\n    createDisplace(data: $data) {\n      ...Displace\n    }\n  }\n  ", "\n"])), exports.DisplaceFragmentDoc);

function useCreateDisplaceMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.CreateDisplaceDocument, baseOptions);
}

exports.useCreateDisplaceMutation = useCreateDisplaceMutation;
exports.UpdateDisplaceDocument = graphql_tag_1["default"](templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  mutation UpdateDisplace(\n    $data: DisplaceUpdateInput!\n    $where: DisplaceWhereUniqueInput!\n  ) {\n    updateDisplace(data: $data, where: $where) {\n      ...Displace\n    }\n  }\n  ", "\n"], ["\n  mutation UpdateDisplace(\n    $data: DisplaceUpdateInput!\n    $where: DisplaceWhereUniqueInput!\n  ) {\n    updateDisplace(data: $data, where: $where) {\n      ...Displace\n    }\n  }\n  ", "\n"])), exports.DisplaceFragmentDoc);

function useUpdateDisplaceMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.UpdateDisplaceDocument, baseOptions);
}

exports.useUpdateDisplaceMutation = useUpdateDisplaceMutation;
exports.DeleteDisplaceDocument = graphql_tag_1["default"](templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  mutation DeleteDisplace($where: DisplaceWhereUniqueInput!) {\n    deleteDisplace(where: $where) {\n      ...Displace\n    }\n  }\n  ", "\n"], ["\n  mutation DeleteDisplace($where: DisplaceWhereUniqueInput!) {\n    deleteDisplace(where: $where) {\n      ...Displace\n    }\n  }\n  ", "\n"])), exports.DisplaceFragmentDoc);

function useDeleteDisplaceMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.DeleteDisplaceDocument, baseOptions);
}

exports.useDeleteDisplaceMutation = useDeleteDisplaceMutation;
exports.GetFullLayerDocument = graphql_tag_1["default"](templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  query GetFullLayer($where: LayerWhereUniqueInput!) {\n    layer(where: $where) {\n      ...FullLayer\n    }\n  }\n  ", "\n"], ["\n  query GetFullLayer($where: LayerWhereUniqueInput!) {\n    layer(where: $where) {\n      ...FullLayer\n    }\n  }\n  ", "\n"])), exports.FullLayerFragmentDoc);

function useGetFullLayerQuery(baseOptions) {
  return ReactApolloHooks.useQuery(exports.GetFullLayerDocument, baseOptions);
}

exports.useGetFullLayerQuery = useGetFullLayerQuery;
exports.GetLayerDocument = graphql_tag_1["default"](templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  query GetLayer($where: LayerWhereUniqueInput!) {\n    layer(where: $where) {\n      ...Layer\n    }\n  }\n  ", "\n"], ["\n  query GetLayer($where: LayerWhereUniqueInput!) {\n    layer(where: $where) {\n      ...Layer\n    }\n  }\n  ", "\n"])), exports.LayerFragmentDoc);

function useGetLayerQuery(baseOptions) {
  return ReactApolloHooks.useQuery(exports.GetLayerDocument, baseOptions);
}

exports.useGetLayerQuery = useGetLayerQuery;
exports.CreateLayerDocument = graphql_tag_1["default"](templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n  mutation CreateLayer($data: LayerCreateInput!) {\n    createLayer(data: $data) {\n      ...Layer\n    }\n  }\n  ", "\n"], ["\n  mutation CreateLayer($data: LayerCreateInput!) {\n    createLayer(data: $data) {\n      ...Layer\n    }\n  }\n  ", "\n"])), exports.LayerFragmentDoc);

function useCreateLayerMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.CreateLayerDocument, baseOptions);
}

exports.useCreateLayerMutation = useCreateLayerMutation;
exports.CreateFullLayerDocument = graphql_tag_1["default"](templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n  mutation CreateFullLayer($data: LayerCreateInput!) {\n    createLayer(data: $data) {\n      ...FullLayer\n    }\n  }\n  ", "\n"], ["\n  mutation CreateFullLayer($data: LayerCreateInput!) {\n    createLayer(data: $data) {\n      ...FullLayer\n    }\n  }\n  ", "\n"])), exports.FullLayerFragmentDoc);

function useCreateFullLayerMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.CreateFullLayerDocument, baseOptions);
}

exports.useCreateFullLayerMutation = useCreateFullLayerMutation;
exports.UpdateLayerDocument = graphql_tag_1["default"](templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n  mutation UpdateLayer(\n    $data: LayerUpdateInput!\n    $where: LayerWhereUniqueInput!\n  ) {\n    updateLayer(data: $data, where: $where) {\n      ...Layer\n    }\n  }\n  ", "\n"], ["\n  mutation UpdateLayer(\n    $data: LayerUpdateInput!\n    $where: LayerWhereUniqueInput!\n  ) {\n    updateLayer(data: $data, where: $where) {\n      ...Layer\n    }\n  }\n  ", "\n"])), exports.LayerFragmentDoc);

function useUpdateLayerMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.UpdateLayerDocument, baseOptions);
}

exports.useUpdateLayerMutation = useUpdateLayerMutation;
exports.DeleteLayerDocument = graphql_tag_1["default"](templateObject_32 || (templateObject_32 = __makeTemplateObject(["\n  mutation DeleteLayer($where: LayerWhereUniqueInput!) {\n    deleteLayer(where: $where) {\n      ...Layer\n    }\n  }\n  ", "\n"], ["\n  mutation DeleteLayer($where: LayerWhereUniqueInput!) {\n    deleteLayer(where: $where) {\n      ...Layer\n    }\n  }\n  ", "\n"])), exports.LayerFragmentDoc);

function useDeleteLayerMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.DeleteLayerDocument, baseOptions);
}

exports.useDeleteLayerMutation = useDeleteLayerMutation;
exports.GetNoiseDocument = graphql_tag_1["default"](templateObject_33 || (templateObject_33 = __makeTemplateObject(["\n  query GetNoise($where: NoiseWhereUniqueInput!) {\n    noise(where: $where) {\n      ...Noise\n    }\n  }\n  ", "\n"], ["\n  query GetNoise($where: NoiseWhereUniqueInput!) {\n    noise(where: $where) {\n      ...Noise\n    }\n  }\n  ", "\n"])), exports.NoiseFragmentDoc);

function useGetNoiseQuery(baseOptions) {
  return ReactApolloHooks.useQuery(exports.GetNoiseDocument, baseOptions);
}

exports.useGetNoiseQuery = useGetNoiseQuery;
exports.CreateNoiseDocument = graphql_tag_1["default"](templateObject_34 || (templateObject_34 = __makeTemplateObject(["\n  mutation CreateNoise($data: NoiseCreateInput!) {\n    createNoise(data: $data) {\n      ...Noise\n    }\n  }\n  ", "\n"], ["\n  mutation CreateNoise($data: NoiseCreateInput!) {\n    createNoise(data: $data) {\n      ...Noise\n    }\n  }\n  ", "\n"])), exports.NoiseFragmentDoc);

function useCreateNoiseMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.CreateNoiseDocument, baseOptions);
}

exports.useCreateNoiseMutation = useCreateNoiseMutation;
exports.UpdateNoiseDocument = graphql_tag_1["default"](templateObject_35 || (templateObject_35 = __makeTemplateObject(["\n  mutation UpdateNoise(\n    $data: NoiseUpdateInput!\n    $where: NoiseWhereUniqueInput!\n  ) {\n    updateNoise(data: $data, where: $where) {\n      ...Noise\n    }\n  }\n  ", "\n"], ["\n  mutation UpdateNoise(\n    $data: NoiseUpdateInput!\n    $where: NoiseWhereUniqueInput!\n  ) {\n    updateNoise(data: $data, where: $where) {\n      ...Noise\n    }\n  }\n  ", "\n"])), exports.NoiseFragmentDoc);

function useUpdateNoiseMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.UpdateNoiseDocument, baseOptions);
}

exports.useUpdateNoiseMutation = useUpdateNoiseMutation;
exports.DeleteNoiseDocument = graphql_tag_1["default"](templateObject_36 || (templateObject_36 = __makeTemplateObject(["\n  mutation DeleteNoise($where: NoiseWhereUniqueInput!) {\n    deleteNoise(where: $where) {\n      ...Noise\n    }\n  }\n  ", "\n"], ["\n  mutation DeleteNoise($where: NoiseWhereUniqueInput!) {\n    deleteNoise(where: $where) {\n      ...Noise\n    }\n  }\n  ", "\n"])), exports.NoiseFragmentDoc);

function useDeleteNoiseMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.DeleteNoiseDocument, baseOptions);
}

exports.useDeleteNoiseMutation = useDeleteNoiseMutation;
exports.GetProjectDocument = graphql_tag_1["default"](templateObject_37 || (templateObject_37 = __makeTemplateObject(["\n  query GetProject($where: ProjectWhereUniqueInput!) {\n    project(where: $where) {\n      ...Project\n    }\n  }\n  ", "\n"], ["\n  query GetProject($where: ProjectWhereUniqueInput!) {\n    project(where: $where) {\n      ...Project\n    }\n  }\n  ", "\n"])), exports.ProjectFragmentDoc);

function useGetProjectQuery(baseOptions) {
  return ReactApolloHooks.useQuery(exports.GetProjectDocument, baseOptions);
}

exports.useGetProjectQuery = useGetProjectQuery;
exports.GetFullProjectDocument = graphql_tag_1["default"](templateObject_38 || (templateObject_38 = __makeTemplateObject(["\n  query GetFullProject($where: ProjectWhereUniqueInput!) {\n    project(where: $where) {\n      ...FullProject\n    }\n  }\n  ", "\n"], ["\n  query GetFullProject($where: ProjectWhereUniqueInput!) {\n    project(where: $where) {\n      ...FullProject\n    }\n  }\n  ", "\n"])), exports.FullProjectFragmentDoc);

function useGetFullProjectQuery(baseOptions) {
  return ReactApolloHooks.useQuery(exports.GetFullProjectDocument, baseOptions);
}

exports.useGetFullProjectQuery = useGetFullProjectQuery;
exports.ListProjectsDocument = graphql_tag_1["default"](templateObject_39 || (templateObject_39 = __makeTemplateObject(["\n  query ListProjects(\n    $after: String\n    $before: String\n    $first: Int\n    $last: Int\n    $orderBy: ProjectOrderByInput\n    $skip: Int\n    $where: ProjectWhereInput\n  ) {\n    projects(\n      after: $after\n      before: $before\n      first: $first\n      last: $last\n      orderBy: $orderBy\n      skip: $skip\n      where: $where\n    ) {\n      ...Project\n    }\n  }\n  ", "\n"], ["\n  query ListProjects(\n    $after: String\n    $before: String\n    $first: Int\n    $last: Int\n    $orderBy: ProjectOrderByInput\n    $skip: Int\n    $where: ProjectWhereInput\n  ) {\n    projects(\n      after: $after\n      before: $before\n      first: $first\n      last: $last\n      orderBy: $orderBy\n      skip: $skip\n      where: $where\n    ) {\n      ...Project\n    }\n  }\n  ", "\n"])), exports.ProjectFragmentDoc);

function useListProjectsQuery(baseOptions) {
  return ReactApolloHooks.useQuery(exports.ListProjectsDocument, baseOptions);
}

exports.useListProjectsQuery = useListProjectsQuery;
exports.CreateProjectDocument = graphql_tag_1["default"](templateObject_40 || (templateObject_40 = __makeTemplateObject(["\n  mutation CreateProject($data: ProjectCreateInput!) {\n    createProject(data: $data) {\n      ...Project\n    }\n  }\n  ", "\n"], ["\n  mutation CreateProject($data: ProjectCreateInput!) {\n    createProject(data: $data) {\n      ...Project\n    }\n  }\n  ", "\n"])), exports.ProjectFragmentDoc);

function useCreateProjectMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.CreateProjectDocument, baseOptions);
}

exports.useCreateProjectMutation = useCreateProjectMutation;
exports.CreateFullProjectDocument = graphql_tag_1["default"](templateObject_41 || (templateObject_41 = __makeTemplateObject(["\n  mutation CreateFullProject($data: ProjectCreateInput!) {\n    createProject(data: $data) {\n      ...FullProject\n    }\n  }\n  ", "\n"], ["\n  mutation CreateFullProject($data: ProjectCreateInput!) {\n    createProject(data: $data) {\n      ...FullProject\n    }\n  }\n  ", "\n"])), exports.FullProjectFragmentDoc);

function useCreateFullProjectMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.CreateFullProjectDocument, baseOptions);
}

exports.useCreateFullProjectMutation = useCreateFullProjectMutation;
exports.UpdateProjectDocument = graphql_tag_1["default"](templateObject_42 || (templateObject_42 = __makeTemplateObject(["\n  mutation UpdateProject(\n    $data: ProjectUpdateInput!\n    $where: ProjectWhereUniqueInput!\n  ) {\n    updateProject(data: $data, where: $where) {\n      ...Project\n    }\n  }\n  ", "\n"], ["\n  mutation UpdateProject(\n    $data: ProjectUpdateInput!\n    $where: ProjectWhereUniqueInput!\n  ) {\n    updateProject(data: $data, where: $where) {\n      ...Project\n    }\n  }\n  ", "\n"])), exports.ProjectFragmentDoc);

function useUpdateProjectMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.UpdateProjectDocument, baseOptions);
}

exports.useUpdateProjectMutation = useUpdateProjectMutation;
exports.DeleteProjectDocument = graphql_tag_1["default"](templateObject_43 || (templateObject_43 = __makeTemplateObject(["\n  mutation DeleteProject($where: ProjectWhereUniqueInput!) {\n    deleteProject(where: $where) {\n      ...Project\n    }\n  }\n  ", "\n"], ["\n  mutation DeleteProject($where: ProjectWhereUniqueInput!) {\n    deleteProject(where: $where) {\n      ...Project\n    }\n  }\n  ", "\n"])), exports.ProjectFragmentDoc);

function useDeleteProjectMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.DeleteProjectDocument, baseOptions);
}

exports.useDeleteProjectMutation = useDeleteProjectMutation;
exports.OnProjectDocument = graphql_tag_1["default"](templateObject_44 || (templateObject_44 = __makeTemplateObject(["\n  subscription OnProject {\n    project {\n      node {\n        ...Project\n      }\n    }\n  }\n  ", "\n"], ["\n  subscription OnProject {\n    project {\n      node {\n        ...Project\n      }\n    }\n  }\n  ", "\n"])), exports.ProjectFragmentDoc);

function useOnProjectSubscription(baseOptions) {
  return ReactApolloHooks.useSubscription(exports.OnProjectDocument, baseOptions);
}

exports.useOnProjectSubscription = useOnProjectSubscription;
exports.GetRampDocument = graphql_tag_1["default"](templateObject_45 || (templateObject_45 = __makeTemplateObject(["\n  query GetRamp($where: RampWhereUniqueInput!) {\n    ramp(where: $where) {\n      ...Ramp\n    }\n  }\n  ", "\n"], ["\n  query GetRamp($where: RampWhereUniqueInput!) {\n    ramp(where: $where) {\n      ...Ramp\n    }\n  }\n  ", "\n"])), exports.RampFragmentDoc);

function useGetRampQuery(baseOptions) {
  return ReactApolloHooks.useQuery(exports.GetRampDocument, baseOptions);
}

exports.useGetRampQuery = useGetRampQuery;
exports.CreateRampDocument = graphql_tag_1["default"](templateObject_46 || (templateObject_46 = __makeTemplateObject(["\n  mutation CreateRamp($data: RampCreateInput!) {\n    createRamp(data: $data) {\n      ...Ramp\n    }\n  }\n  ", "\n"], ["\n  mutation CreateRamp($data: RampCreateInput!) {\n    createRamp(data: $data) {\n      ...Ramp\n    }\n  }\n  ", "\n"])), exports.RampFragmentDoc);

function useCreateRampMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.CreateRampDocument, baseOptions);
}

exports.useCreateRampMutation = useCreateRampMutation;
exports.UpdateRampDocument = graphql_tag_1["default"](templateObject_47 || (templateObject_47 = __makeTemplateObject(["\n  mutation UpdateRamp($data: RampUpdateInput!, $where: RampWhereUniqueInput!) {\n    updateRamp(data: $data, where: $where) {\n      ...Ramp\n    }\n  }\n  ", "\n"], ["\n  mutation UpdateRamp($data: RampUpdateInput!, $where: RampWhereUniqueInput!) {\n    updateRamp(data: $data, where: $where) {\n      ...Ramp\n    }\n  }\n  ", "\n"])), exports.RampFragmentDoc);

function useUpdateRampMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.UpdateRampDocument, baseOptions);
}

exports.useUpdateRampMutation = useUpdateRampMutation;
exports.DeleteRampDocument = graphql_tag_1["default"](templateObject_48 || (templateObject_48 = __makeTemplateObject(["\n  mutation DeleteRamp($where: RampWhereUniqueInput!) {\n    deleteRamp(where: $where) {\n      ...Ramp\n    }\n  }\n  ", "\n"], ["\n  mutation DeleteRamp($where: RampWhereUniqueInput!) {\n    deleteRamp(where: $where) {\n      ...Ramp\n    }\n  }\n  ", "\n"])), exports.RampFragmentDoc);

function useDeleteRampMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.DeleteRampDocument, baseOptions);
}

exports.useDeleteRampMutation = useDeleteRampMutation;
exports.GetRampKeyDocument = graphql_tag_1["default"](templateObject_49 || (templateObject_49 = __makeTemplateObject(["\n  query GetRampKey($where: RampKeyWhereUniqueInput!) {\n    rampKey(where: $where) {\n      ...RampKey\n    }\n  }\n  ", "\n"], ["\n  query GetRampKey($where: RampKeyWhereUniqueInput!) {\n    rampKey(where: $where) {\n      ...RampKey\n    }\n  }\n  ", "\n"])), exports.RampKeyFragmentDoc);

function useGetRampKeyQuery(baseOptions) {
  return ReactApolloHooks.useQuery(exports.GetRampKeyDocument, baseOptions);
}

exports.useGetRampKeyQuery = useGetRampKeyQuery;
exports.CreateRampKeyDocument = graphql_tag_1["default"](templateObject_50 || (templateObject_50 = __makeTemplateObject(["\n  mutation CreateRampKey($data: RampKeyCreateInput!) {\n    createRampKey(data: $data) {\n      ...RampKey\n    }\n  }\n  ", "\n"], ["\n  mutation CreateRampKey($data: RampKeyCreateInput!) {\n    createRampKey(data: $data) {\n      ...RampKey\n    }\n  }\n  ", "\n"])), exports.RampKeyFragmentDoc);

function useCreateRampKeyMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.CreateRampKeyDocument, baseOptions);
}

exports.useCreateRampKeyMutation = useCreateRampKeyMutation;
exports.UpdateRampKeyDocument = graphql_tag_1["default"](templateObject_51 || (templateObject_51 = __makeTemplateObject(["\n  mutation UpdateRampKey(\n    $data: RampKeyUpdateInput!\n    $where: RampKeyWhereUniqueInput!\n  ) {\n    updateRampKey(data: $data, where: $where) {\n      ...RampKey\n    }\n  }\n  ", "\n"], ["\n  mutation UpdateRampKey(\n    $data: RampKeyUpdateInput!\n    $where: RampKeyWhereUniqueInput!\n  ) {\n    updateRampKey(data: $data, where: $where) {\n      ...RampKey\n    }\n  }\n  ", "\n"])), exports.RampKeyFragmentDoc);

function useUpdateRampKeyMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.UpdateRampKeyDocument, baseOptions);
}

exports.useUpdateRampKeyMutation = useUpdateRampKeyMutation;
exports.DeleteRampKeyDocument = graphql_tag_1["default"](templateObject_52 || (templateObject_52 = __makeTemplateObject(["\n  mutation DeleteRampKey($where: RampKeyWhereUniqueInput!) {\n    deleteRampKey(where: $where) {\n      ...RampKey\n    }\n  }\n  ", "\n"], ["\n  mutation DeleteRampKey($where: RampKeyWhereUniqueInput!) {\n    deleteRampKey(where: $where) {\n      ...RampKey\n    }\n  }\n  ", "\n"])), exports.RampKeyFragmentDoc);

function useDeleteRampKeyMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.DeleteRampKeyDocument, baseOptions);
}

exports.useDeleteRampKeyMutation = useDeleteRampKeyMutation;
exports.GetRemapDocument = graphql_tag_1["default"](templateObject_53 || (templateObject_53 = __makeTemplateObject(["\n  query GetRemap($where: RemapWhereUniqueInput!) {\n    remap(where: $where) {\n      ...Remap\n    }\n  }\n  ", "\n"], ["\n  query GetRemap($where: RemapWhereUniqueInput!) {\n    remap(where: $where) {\n      ...Remap\n    }\n  }\n  ", "\n"])), exports.RemapFragmentDoc);

function useGetRemapQuery(baseOptions) {
  return ReactApolloHooks.useQuery(exports.GetRemapDocument, baseOptions);
}

exports.useGetRemapQuery = useGetRemapQuery;
exports.CreateRemapDocument = graphql_tag_1["default"](templateObject_54 || (templateObject_54 = __makeTemplateObject(["\n  mutation CreateRemap($data: RemapCreateInput!) {\n    createRemap(data: $data) {\n      ...Remap\n    }\n  }\n  ", "\n"], ["\n  mutation CreateRemap($data: RemapCreateInput!) {\n    createRemap(data: $data) {\n      ...Remap\n    }\n  }\n  ", "\n"])), exports.RemapFragmentDoc);

function useCreateRemapMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.CreateRemapDocument, baseOptions);
}

exports.useCreateRemapMutation = useCreateRemapMutation;
exports.UpdateRemapDocument = graphql_tag_1["default"](templateObject_55 || (templateObject_55 = __makeTemplateObject(["\n  mutation UpdateRemap(\n    $data: RemapUpdateInput!\n    $where: RemapWhereUniqueInput!\n  ) {\n    updateRemap(data: $data, where: $where) {\n      ...Remap\n    }\n  }\n  ", "\n"], ["\n  mutation UpdateRemap(\n    $data: RemapUpdateInput!\n    $where: RemapWhereUniqueInput!\n  ) {\n    updateRemap(data: $data, where: $where) {\n      ...Remap\n    }\n  }\n  ", "\n"])), exports.RemapFragmentDoc);

function useUpdateRemapMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.UpdateRemapDocument, baseOptions);
}

exports.useUpdateRemapMutation = useUpdateRemapMutation;
exports.DeleteRemapDocument = graphql_tag_1["default"](templateObject_56 || (templateObject_56 = __makeTemplateObject(["\n  mutation DeleteRemap($where: RemapWhereUniqueInput!) {\n    deleteRemap(where: $where) {\n      ...Remap\n    }\n  }\n  ", "\n"], ["\n  mutation DeleteRemap($where: RemapWhereUniqueInput!) {\n    deleteRemap(where: $where) {\n      ...Remap\n    }\n  }\n  ", "\n"])), exports.RemapFragmentDoc);

function useDeleteRemapMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.DeleteRemapDocument, baseOptions);
}

exports.useDeleteRemapMutation = useDeleteRemapMutation;
exports.GetTransformDocument = graphql_tag_1["default"](templateObject_57 || (templateObject_57 = __makeTemplateObject(["\n  query GetTransform($where: TransformWhereUniqueInput!) {\n    transform(where: $where) {\n      ...Transform\n    }\n  }\n  ", "\n"], ["\n  query GetTransform($where: TransformWhereUniqueInput!) {\n    transform(where: $where) {\n      ...Transform\n    }\n  }\n  ", "\n"])), exports.TransformFragmentDoc);

function useGetTransformQuery(baseOptions) {
  return ReactApolloHooks.useQuery(exports.GetTransformDocument, baseOptions);
}

exports.useGetTransformQuery = useGetTransformQuery;
exports.CreateTransformDocument = graphql_tag_1["default"](templateObject_58 || (templateObject_58 = __makeTemplateObject(["\n  mutation CreateTransform($data: TransformCreateInput!) {\n    createTransform(data: $data) {\n      ...Transform\n    }\n  }\n  ", "\n"], ["\n  mutation CreateTransform($data: TransformCreateInput!) {\n    createTransform(data: $data) {\n      ...Transform\n    }\n  }\n  ", "\n"])), exports.TransformFragmentDoc);

function useCreateTransformMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.CreateTransformDocument, baseOptions);
}

exports.useCreateTransformMutation = useCreateTransformMutation;
exports.UpdateTransformDocument = graphql_tag_1["default"](templateObject_59 || (templateObject_59 = __makeTemplateObject(["\n  mutation UpdateTransform(\n    $data: TransformUpdateInput!\n    $where: TransformWhereUniqueInput!\n  ) {\n    updateTransform(data: $data, where: $where) {\n      ...Transform\n    }\n  }\n  ", "\n"], ["\n  mutation UpdateTransform(\n    $data: TransformUpdateInput!\n    $where: TransformWhereUniqueInput!\n  ) {\n    updateTransform(data: $data, where: $where) {\n      ...Transform\n    }\n  }\n  ", "\n"])), exports.TransformFragmentDoc);

function useUpdateTransformMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.UpdateTransformDocument, baseOptions);
}

exports.useUpdateTransformMutation = useUpdateTransformMutation;
exports.DeleteTransformDocument = graphql_tag_1["default"](templateObject_60 || (templateObject_60 = __makeTemplateObject(["\n  mutation DeleteTransform($where: TransformWhereUniqueInput!) {\n    deleteTransform(where: $where) {\n      ...Transform\n    }\n  }\n  ", "\n"], ["\n  mutation DeleteTransform($where: TransformWhereUniqueInput!) {\n    deleteTransform(where: $where) {\n      ...Transform\n    }\n  }\n  ", "\n"])), exports.TransformFragmentDoc);

function useDeleteTransformMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.DeleteTransformDocument, baseOptions);
}

exports.useDeleteTransformMutation = useDeleteTransformMutation;
exports.GetViewerDocument = graphql_tag_1["default"](templateObject_61 || (templateObject_61 = __makeTemplateObject(["\n  query GetViewer($where: ViewerWhereUniqueInput!) {\n    viewer(where: $where) {\n      ...Viewer\n    }\n  }\n  ", "\n"], ["\n  query GetViewer($where: ViewerWhereUniqueInput!) {\n    viewer(where: $where) {\n      ...Viewer\n    }\n  }\n  ", "\n"])), exports.ViewerFragmentDoc);

function useGetViewerQuery(baseOptions) {
  return ReactApolloHooks.useQuery(exports.GetViewerDocument, baseOptions);
}

exports.useGetViewerQuery = useGetViewerQuery;
exports.GetFullViewerDocument = graphql_tag_1["default"](templateObject_62 || (templateObject_62 = __makeTemplateObject(["\n  query GetFullViewer($where: ViewerWhereUniqueInput!) {\n    viewer(where: $where) {\n      ...FullViewer\n    }\n  }\n  ", "\n"], ["\n  query GetFullViewer($where: ViewerWhereUniqueInput!) {\n    viewer(where: $where) {\n      ...FullViewer\n    }\n  }\n  ", "\n"])), exports.FullViewerFragmentDoc);

function useGetFullViewerQuery(baseOptions) {
  return ReactApolloHooks.useQuery(exports.GetFullViewerDocument, baseOptions);
}

exports.useGetFullViewerQuery = useGetFullViewerQuery;
exports.ListViewersDocument = graphql_tag_1["default"](templateObject_63 || (templateObject_63 = __makeTemplateObject(["\n  query ListViewers(\n    $after: String\n    $before: String\n    $first: Int\n    $last: Int\n    $orderBy: ViewerOrderByInput\n    $skip: Int\n    $where: ViewerWhereInput\n  ) {\n    viewers(\n      after: $after\n      before: $before\n      first: $first\n      last: $last\n      orderBy: $orderBy\n      skip: $skip\n      where: $where\n    ) {\n      ...Viewer\n    }\n  }\n  ", "\n"], ["\n  query ListViewers(\n    $after: String\n    $before: String\n    $first: Int\n    $last: Int\n    $orderBy: ViewerOrderByInput\n    $skip: Int\n    $where: ViewerWhereInput\n  ) {\n    viewers(\n      after: $after\n      before: $before\n      first: $first\n      last: $last\n      orderBy: $orderBy\n      skip: $skip\n      where: $where\n    ) {\n      ...Viewer\n    }\n  }\n  ", "\n"])), exports.ViewerFragmentDoc);

function useListViewersQuery(baseOptions) {
  return ReactApolloHooks.useQuery(exports.ListViewersDocument, baseOptions);
}

exports.useListViewersQuery = useListViewersQuery;
exports.CreateViewerDocument = graphql_tag_1["default"](templateObject_64 || (templateObject_64 = __makeTemplateObject(["\n  mutation CreateViewer($data: ViewerCreateInput!) {\n    createViewer(data: $data) {\n      ...Viewer\n    }\n  }\n  ", "\n"], ["\n  mutation CreateViewer($data: ViewerCreateInput!) {\n    createViewer(data: $data) {\n      ...Viewer\n    }\n  }\n  ", "\n"])), exports.ViewerFragmentDoc);

function useCreateViewerMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.CreateViewerDocument, baseOptions);
}

exports.useCreateViewerMutation = useCreateViewerMutation;
exports.UpdateViewerDocument = graphql_tag_1["default"](templateObject_65 || (templateObject_65 = __makeTemplateObject(["\n  mutation UpdateViewer(\n    $data: ViewerUpdateInput!\n    $where: ViewerWhereUniqueInput!\n  ) {\n    updateViewer(data: $data, where: $where) {\n      ...Viewer\n    }\n  }\n  ", "\n"], ["\n  mutation UpdateViewer(\n    $data: ViewerUpdateInput!\n    $where: ViewerWhereUniqueInput!\n  ) {\n    updateViewer(data: $data, where: $where) {\n      ...Viewer\n    }\n  }\n  ", "\n"])), exports.ViewerFragmentDoc);

function useUpdateViewerMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.UpdateViewerDocument, baseOptions);
}

exports.useUpdateViewerMutation = useUpdateViewerMutation;
exports.DeleteViewerDocument = graphql_tag_1["default"](templateObject_66 || (templateObject_66 = __makeTemplateObject(["\n  mutation DeleteViewer($where: ViewerWhereUniqueInput!) {\n    deleteViewer(where: $where) {\n      ...Viewer\n    }\n  }\n  ", "\n"], ["\n  mutation DeleteViewer($where: ViewerWhereUniqueInput!) {\n    deleteViewer(where: $where) {\n      ...Viewer\n    }\n  }\n  ", "\n"])), exports.ViewerFragmentDoc);

function useDeleteViewerMutation(baseOptions) {
  return ReactApolloHooks.useMutation(exports.DeleteViewerDocument, baseOptions);
}

exports.useDeleteViewerMutation = useDeleteViewerMutation;
var result = {
  __schema: {
    types: []
  }
};
exports["default"] = result;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31, templateObject_32, templateObject_33, templateObject_34, templateObject_35, templateObject_36, templateObject_37, templateObject_38, templateObject_39, templateObject_40, templateObject_41, templateObject_42, templateObject_43, templateObject_44, templateObject_45, templateObject_46, templateObject_47, templateObject_48, templateObject_49, templateObject_50, templateObject_51, templateObject_52, templateObject_53, templateObject_54, templateObject_55, templateObject_56, templateObject_57, templateObject_58, templateObject_59, templateObject_60, templateObject_61, templateObject_62, templateObject_63, templateObject_64, templateObject_65, templateObject_66;