import React from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import useRouter from 'use-react-router'

export function Nav() {
  const router = useRouter()
  const key = router.location.pathname.includes('viewers')
    ? 'viewers'
    : 'projects'

  return (
    <>
      <Menu
        mode="horizontal"
        selectedKeys={[key]}
        style={{
          padding: '0 16px',
          position: 'fixed',
          width: '100%',
          zIndex: 9999,
        }}
      >
        <Menu.Item key="viewers">
          <Link to="/viewers">Viewers</Link>
        </Menu.Item>
        <Menu.Item key="projects">
          <Link to="/projects">Projects</Link>
        </Menu.Item>
      </Menu>
      <div style={{ height: '50px' }} />
    </>
  )
}
