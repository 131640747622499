import {
  useCreateProjectMutation,
  ListProjectsQuery,
  ListProjectsDocument,
  useDeleteProjectMutation,
  ProjectFragment,
  ProjectFragmentDoc,
} from '@lumn-color/hooks'
import { useCallback } from 'react'
import { useApolloClient } from 'react-apollo-hooks'
import produce from 'immer'
import { autoId, MutationHookVariablesData } from 'utils'

export function useCreateProject() {
  const create = useCreateProjectMutation()
  return useCallback(
    (data: MutationHookVariablesData<typeof useCreateProjectMutation>) => {
      const variables = {
        data: {
          id: autoId(),
          ...data,
        },
      }
      return create({
        variables,
        optimisticResponse: {
          __typename: 'Mutation',
          createProject: {
            __typename: 'Project',
            ...variables.data,
          },
        },
        update(proxy, result) {
          const queryOptions = {
            query: ListProjectsDocument,
          }
          const query = proxy.readQuery<ListProjectsQuery>(queryOptions)!
          proxy.writeQuery({
            ...queryOptions,
            data: produce(query, draft => {
              draft.projects.push(result.data!.createProject)
            }),
          })
        },
      })
    },
    [create],
  )
}

export function useDeleteProject() {
  const client = useApolloClient()
  const deleteProject = useDeleteProjectMutation()
  const onDelete = useCallback(
    (id: string) =>
      deleteProject({
        variables: {
          where: {
            id,
          },
        },
        optimisticResponse() {
          return {
            __typename: 'Mutation',
            deleteProject: client.cache.readFragment<ProjectFragment>({
              id: `Project:${id}`,
              fragment: ProjectFragmentDoc,
              fragmentName: 'Project',
            })!,
          }
        },
        update(proxy, mutationResult) {
          proxy.writeQuery({
            query: ListProjectsDocument,
            data: produce(
              proxy.readQuery<ListProjectsQuery>({
                query: ListProjectsDocument,
              })!,
              draft => {
                draft.projects.splice(
                  draft.projects.findIndex(_ => _.id === id),
                  1,
                )
              },
            ),
          })
          proxy.delete(`Project:${id}`) // note: could also delete everything deeper...
        },
      }),
    [deleteProject, client.cache],
  )

  return onDelete
}
