import { Icon } from 'antd'
import * as React from 'react'
import { Model, Field as IField } from 'types/model'
import { UpdateFieldResult } from 'hooks/useUpdateFieldSimple'
import { SimpleField } from './SimpleField'
import { ColorField } from './ColorField'
import { SwitchField } from './SwitchField'
import { SelectField, SelectOption } from './SelectField'
import { SelectOperatorField } from './SelectOperatorField'
import useToggle from 'react-use/lib/useToggle'

export type FieldProps<M extends Model> = {
  id: string
  label: string
  type?: 'string' | 'number' | 'color' | 'boolean' | 'enum' | 'input'
  expressions?: boolean
  options?: SelectOption[]
  fields: IField<M>[] // must be static
  wait?: number
  useUpdateField: (
    options: {
      id: string
      field: IField<M>
      timeout?: number
    },
  ) => UpdateFieldResult<M, IField<M>>
}

function FieldTemplate<M extends Model>(props: FieldProps<M>) {
  const fieldUpdaters = props.fields.map(field =>
    props.useUpdateField({
      id: props.id,
      field,
      timeout: props.wait,
    }),
  )

  const expressionFieldUpdaters = (props.expressions ? props.fields : []).map(
    field =>
      props.useUpdateField({
        id: props.id,
        // @ts-ignore FIXME
        field: `exp_${field}`,
        timeout: props.wait,
      }),
  )

  const [show, toggleShow] = useToggle(false)

  return (
    <>
      {renderField()}
      {props.expressions && (
        <Icon onClick={toggleShow} type={show ? 'minus' : 'plus'} />
      )}
      {show && renderExpressionField()}
    </>
  )

  function renderField() {
    switch (props.type) {
      case 'color':
        return <ColorField {...props} fieldUpdaters={fieldUpdaters} />
      case 'boolean':
        return <SwitchField {...props} fieldUpdaters={fieldUpdaters} />
      case 'enum':
        return (
          <SelectField
            {...props}
            options={props.options!}
            fieldUpdaters={fieldUpdaters}
          />
        )
      case 'input':
        return <SelectOperatorField {...props} fieldUpdaters={fieldUpdaters} />
      default:
        return (
          <SimpleField
            {...props}
            type={props.type}
            fieldUpdaters={fieldUpdaters}
          />
        )
    }
  }

  function renderExpressionField() {
    if (!props.expressions) return null
    return (
      <SimpleField
        {...props}
        label=""
        type="string"
        stacked
        fieldUpdaters={expressionFieldUpdaters}
      />
    )
  }
}

// @ts-ignore TODO figure out how to apply generics correctly
export const Field: typeof FieldTemplate = React.memo(FieldTemplate)
